import { noneKey } from 'app/constants'
import { memoize } from 'utils/misc'

export const getVolumeSpec = (disks) => {
  return disks.map((disk) => {
    const volumeName = disk?.volumeName || `volume-${disk.index + 1}`
    if (['httpUrl', 'registryUrl', 'disk', 'clone'].includes(disk?.sourceType)) {
      return {
        name: volumeName,
        dataVolume: {
          name: disk?.dataTemplateName || `${disk.vmName}-disk-${disk.index + 1}`,
        },
      }
    } else if (disk?.sourceType === 'existing') {
      return {
        name: volumeName,
        persistentVolumeClaim: {
          claimName: disk.selectedPvcs[0].name,
        },
      }
    } else if (disk?.sourceType === 'volume') {
      return {
        name: volumeName,
        dataVolume: {
          name: disk.selectedDataVolumes[0].name,
        },
      }
    }
    return {}
  })
}

export const getDiskSpec = (disks) => {
  return disks.map((disk) => {
    const volumeName = disk?.volumeName || `volume-${disk.index + 1}`
    return {
      name: volumeName,
      disk: {
        bus: 'virtio',
      },
    }
  })
}

export const getDataVolumeTemplatesSpec = (disks, vmName = '') => {
  return disks
    .filter((disk) => {
      return ['httpUrl', 'registryUrl', 'disk', 'clone'].includes(disk.sourceType)
    })
    .map((disk) => {
      return {
        metadata: {
          name: disk?.dataTemplateName || `${vmName}-disk-${disk.index + 1}`,
        },
        spec: getDataVolumeObject(disk),
      }
    })
}

const httpObject = (disk) => ({
  source: {
    http: {
      url: disk.httpUrl,
    },
  },
  pvc: {
    accessModes: [disk.accessMode],
    resources: {
      requests: {
        storage: `${disk.storageSize}Gi`,
      },
    },
    ...(disk.storageClass !== noneKey && { storageClassName: disk.storageClass }),
  },
})

const registryObject = (disk) => ({
  source: {
    registry: {
      url: disk.registryUrl,
    },
  },
  pvc: {
    accessModes: [disk.accessMode],
    resources: {
      requests: {
        storage: `${disk.storageSize}Gi`,
      },
    },
    ...(disk.storageClass !== noneKey && { storageClassName: disk.storageClass }),
  },
})

const diskObject = (disk) => ({
  source: {
    upload: {},
  },
  pvc: {
    accessModes: [disk.accessMode],
    resources: {
      requests: {
        storage: `${disk.storageSize}Gi`,
      },
    },
  },
})

const cloneObject = (disk) => ({
  pvc: {
    accessModes: [disk.accessMode],
    resources: {
      requests: {
        storage: disk.selectedPvcs[0].spec?.resources?.requests?.storage,
      },
    },
  },
  source: {
    pvc: {
      namespace: disk.selectedPvcs[0].namespace,
      name: disk.selectedPvcs[0].name,
    },
  },
})

const getDataVolumeObject = (disk) => {
  const objectMethod = {
    httpUrl: httpObject,
    registryUrl: registryObject,
    disk: diskObject,
    clone: cloneObject,
  }[disk?.sourceType]
  const object = objectMethod ? objectMethod(disk) : null
  return object
}

export const getVolumeJson = memoize(({ storageDisks = [], vmName = '', cloudInit = '' }) => {
  const withIndex = storageDisks.map((disk, idx) => {
    return {
      ...disk,
      index: idx,
      vmName,
    }
  })

  const diskSpec = getDiskSpec(withIndex)

  const fullDiskSpec = [
    ...diskSpec,
    ...(cloudInit
      ? [
          {
            name: 'cloudinit',
            disk: {
              bus: 'virtio',
            },
          },
        ]
      : []),
  ]

  const volumeSpec = getVolumeSpec(withIndex)

  const fullVolumeSpec = [
    ...volumeSpec,
    ...(cloudInit
      ? [
          {
            name: 'cloudinit',
            cloudInitNoCloud: {
              userDataBase64: window.btoa(unescape(encodeURIComponent(cloudInit))),
            },
          },
        ]
      : []),
  ]

  const dataVolumeTemplates = getDataVolumeTemplatesSpec(withIndex, vmName)

  return {
    diskSpec: fullDiskSpec,
    volumeSpec: fullVolumeSpec,
    dataVolumeTemplates,
  }
})

export const getVirtualMachineJson = ({
  wizardContext,
  diskSpec,
  volumeSpec,
  dataVolumeTemplates,
  resources,
  interfaceSpec,
  networkSpec,
  instanceType,
}) => {
  return {
    apiVersion: 'kubevirt.io/v1',
    kind: 'VirtualMachine',
    metadata: {
      name: wizardContext.name,
    },
    spec: {
      runStrategy: wizardContext.runStrategy,
      instancetype: instanceType
        ? {
            kind: instanceType?.kind,
            name: instanceType?.name,
          }
        : undefined,
      template: {
        metadata: {
          labels: {},
        },
        spec: {
          domain: {
            devices: {
              interfaces: interfaceSpec,
              disks: diskSpec,
            },
            ...resources,
          },
          networks: networkSpec,
          volumes: volumeSpec,
        },
      },
      dataVolumeTemplates,
    },
  }
}

// dataVolumeTemplates not allowed for VMI
export const getVirtualMachineInstanceJson = ({
  wizardContext,
  diskSpec,
  volumeSpec,
  resources,
  interfaceSpec,
  networkSpec,
}) => {
  return {
    apiVersion: 'kubevirt.io/v1',
    kind: 'VirtualMachineInstance',
    metadata: {
      name: wizardContext.name,
    },
    spec: {
      domain: {
        devices: {
          interfaces: interfaceSpec,
          disks: diskSpec,
        },
        ...resources,
      },
      networks: networkSpec,
      volumes: volumeSpec,
    },
  }
}

export const getNetworkJson = (wizardContext) => {
  const interfaceSpec = [
    {
      name: 'default',
      [wizardContext.podNetworkBindingType]: {},
    },
    ...wizardContext.selectedNetworks.map((network, index) => {
      return {
        name: `${wizardContext?.name}-interface-${index + 1}`,
        bridge: {},
      }
    }),
  ]

  const networkSpec = [
    {
      name: 'default',
      pod: {},
    },
    ...wizardContext.selectedNetworks.map((network, index) => {
      return {
        name: `${wizardContext?.name}-interface-${index + 1}`,
        multus: {
          networkName: network.name,
        },
      }
    }),
  ]

  return {
    interfaceSpec,
    networkSpec,
  }
}
