import React, { FC, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormWrapper from 'core/components/FormWrapper'
import Wizard from 'core/components/wizard/Wizard'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createInstanceType, createClusterInstanceType } from './actions'
import WizardStep from 'core/components/wizard/WizardStep'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import TextField from 'core/components/validatedForm/TextField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import generateTestId from 'app/utils/test-helpers'
import UnitPicklist from '../add/UnitPicklist'
import CapiSpec from 'app/plugins/infrastructure/components/clusters/aws/capi/CapiSpec' // CapiSpec component useful for any wizard, should be renamed & moved
import Row from 'core/containers/Row'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import HugePagesPicklist from './HugePagesPicklist'

const useStyles = makeStyles<Theme>((theme) => ({
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
}))

const listUrl = routes.instanceTypes.list.path()

export default function CreateInstanceTypePage() {
  const classes = useStyles()
  const { history } = useReactRouter()
  const [initialContext, setInitialContext] = useState({
    memoryUnit: 'Gi',
    scopeToNamespace: true,
    // dedicatedCpuPlacement: false,
    hugePages: '',
  })

  const {
    updating: creatingInstanceType,
    update: createInstanceTypeFn,
    error: errorCreatingInstanceType,
  } = useUpdateAction(createInstanceType)
  const {
    updating: creatingClusterInstanceType,
    update: createClusterInstanceTypeFn,
    error: errorCreatingClusterInstanceType,
  } = useUpdateAction(createClusterInstanceType)

  const handleSubmit = async (data) => {
    const body = {
      apiVersion: 'instancetype.kubevirt.io/v1alpha2',
      kind: data.scopeToNamespace
        ? 'VirtualMachineInstancetype'
        : 'VirtualMachineClusterInstancetype',
      metadata: {
        name: data?.name,
      },
      spec: {
        cpu: {
          guest: data?.vcpus,
          // dedicatedCPUPlacement: data.dedicatedCpuPlacement,
        },
        memory: {
          guest: `${data?.memory}${data?.memoryUnit}`,
          // hugepages: data?.hugePages ? { pageSize: data.hugePages } : undefined,
        },
      },
    }
    const createFn = data.scopeToNamespace ? createInstanceTypeFn : createClusterInstanceTypeFn
    const { success, response: result } = await createFn({
      ...data,
      body,
    })

    if (success) {
      history.push(listUrl)
    }
  }

  return (
    <FormWrapper backUrl={listUrl} loading={creatingInstanceType || creatingClusterInstanceType}>
      <DocumentMeta title="Create Instancetype" bodyClasses={['form-view']} />
      {!!initialContext && (
        <Wizard
          error={errorCreatingInstanceType || errorCreatingClusterInstanceType}
          onComplete={handleSubmit}
          context={initialContext}
        >
          {({ wizardContext, setWizardContext, onNext }) => (
            <>
              <WizardStep
                summaryTitle="Create Instancetype"
                summaryIcon="kubevirt"
                stepId="settings"
                label="Settings"
              >
                <ValidatedForm
                  initialValues={wizardContext}
                  onSubmit={setWizardContext}
                  triggerSubmit={onNext}
                >
                  {({ values }) => (
                    <>
                      <CapiSpec title="Basic Settings">
                        <Row minItemWidth="300" gap={24}>
                          <TextField id="name" label="Name" value={values?.name} required />
                        </Row>
                      </CapiSpec>
                      <CapiSpec title="Scope">
                        <Row minItemWidth="300" gap={24}>
                          <PicklistField
                            DropdownComponent={ClusterPicklist}
                            id="clusterId"
                            data-testid={generateTestId('clusterid', 'dropdown')}
                            label="Cluster"
                            onChange={(value) => setWizardContext({ clusterId: value })}
                            value={values.clusterId}
                            showAll={false}
                            onlyHealthyClusters
                            setInitialCluster
                            required
                          />
                          <CheckboxField
                            id="scopeToNamespace"
                            label="Scope to Namespace"
                            onChange={(value) => setWizardContext({ scopeToNamespace: value })}
                            value={values.scopeToNamespace}
                            info="If the Instancetype is scoped to a namespace, it will be usable only for VMs within that namespace. If not, the Instancetype will be available for VMs across all namespaces."
                          />
                          {values?.scopeToNamespace && (
                            <PicklistField
                              DropdownComponent={NamespacePicklist}
                              disabled={!values.clusterId}
                              id="namespace"
                              data-testid={generateTestId('namespace', 'dropdown')}
                              label="Namespace"
                              onChange={(value) => setWizardContext({ namespace: value })}
                              clusterId={values.clusterId}
                              value={values.namespace}
                              showAll={false}
                              setInitialNamespace
                              required
                            />
                          )}
                        </Row>
                      </CapiSpec>
                      <CapiSpec title="Resources">
                        <Row minItemWidth="300" gap={24}>
                          <TextField
                            id="vcpus"
                            label="vCPUs"
                            value={values?.vcpus}
                            type="number"
                            min="1"
                            required
                          />
                          {/* <ToggleSwitchField
                            id="dedicatedCpuPlacement"
                            label="Dedicated CPU Placement"
                            onChange={(value) => setWizardContext({ dedicatedCpuPlacement: value })}
                            value={values?.dedicatedCpuPlacement}
                          /> */}
                          <div className={classes.withUnits}>
                            <TextField
                              id="memory"
                              label="Guest Memory"
                              value={values?.memory}
                              type="number"
                              min="1"
                              required
                            />
                            <PicklistField
                              DropdownComponent={UnitPicklist}
                              id="memoryUnit"
                              label="Unit"
                              value={values?.memoryUnit}
                              onChange={(value) => setWizardContext({ memoryUnit: value })}
                              className={classes.unitsDropdown}
                            />
                          </div>
                          {/* <PicklistField
                            DropdownComponent={HugePagesPicklist}
                            id="hugePages"
                            label="Huge Pages (optional)"
                            value={values?.hugePages}
                            onChange={(value) => setWizardContext({ hugePages: value })}
                          /> */}
                        </Row>
                      </CapiSpec>
                    </>
                  )}
                </ValidatedForm>
              </WizardStep>
            </>
          )}
        </Wizard>
      )}
    </FormWrapper>
  )
}
