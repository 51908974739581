import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { updateVirtualMachine } from '../new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import DocumentMeta from 'core/components/DocumentMeta'
import ModalForm from 'core/elements/modal/ModalForm'
import { noop } from 'utils/fp'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import useParams from 'core/hooks/useParams'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import Alert from 'core/components/Alert'
import Radio from 'core/elements/input/Radio'
import UnitPicklist from '../add/UnitPicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { customValidator } from 'core/utils/fieldValidators'
import { splitKubernetesUnitString } from './helpers'

const useStyles = makeStyles<Theme>((theme) => ({
  advancedOptionsLabel: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
}))

const getPatchOperations = (vm, body) => {
  const domain = vm?.spec?.template?.spec?.domain
  const bodyDomain = body?.spec?.template?.spec?.domain
  if (!domain || !bodyDomain) {
    return []
  }
  const operations = []
  // For each property, if it used to exist on the VM and it no longer exists on the params
  // then add a 'remove' operation for it
  // TODO: Consider creating a function to find differing paths... potentially use ramda path
  // and have a dictionary of property to path
  if (domain?.cpu?.cores && !bodyDomain?.cpu?.cores) {
    operations.push({ op: 'remove', path: '/spec/template/spec/domain/cpu/cores' })
  }
  if (domain?.cpu?.sockets && !bodyDomain?.cpu?.sockets) {
    operations.push({ op: 'remove', path: '/spec/template/spec/domain/cpu/sockets' })
  }
  if (domain?.cpu?.threads && !bodyDomain?.cpu?.threads) {
    operations.push({ op: 'remove', path: '/spec/template/spec/domain/cpu/threads' })
  }
  if (domain?.resources?.limits?.cpu && !bodyDomain?.resources?.limits?.cpu) {
    operations.push({ op: 'remove', path: '/spec/template/spec/domain/resources/limits/cpu' })
  }
  if (domain?.resources?.limits?.memory && !bodyDomain?.resources?.limits.memory) {
    operations.push({ op: 'remove', path: '/spec/template/spec/domain/resources/limits/memory' })
  }
  if (domain?.resources?.requests?.cpu && !bodyDomain?.resources?.requests?.cpu) {
    operations.push({ op: 'remove', path: '/spec/template/spec/domain/resources/requests/cpu' })
  }
  if (domain?.resources?.requests?.memory && !bodyDomain?.resources?.requests?.memory) {
    operations.push({ op: 'remove', path: '/spec/template/spec/domain/resources/requests/memory' })
  }
  if (domain?.memory?.guest && !bodyDomain?.memory?.guest) {
    operations.push({ op: 'remove', path: '/spec/template/spec/domain/memory/guest' })
  }
  if (domain?.cpu?.dedicatedCpuPlacement && !bodyDomain.cpu?.dedicatedCpuPlacement) {
    operations.push({ op: 'remove', path: '/spec/template/spec/domain/cpu/dedicatedCpuPlacement' })
  }
  return operations
}

export default function EditVmDialog({ rows: [virtualMachine], onClose }) {
  const classes = useStyles()
  const { update: updateFn, updating } = useUpdateAction(updateVirtualMachine)
  const defaultParams = useMemo(() => {
    const dedicatedCpuPlacement =
      virtualMachine?.spec?.template?.spec?.domain?.cpu?.dedicatedCpuPlacement
    const configType =
      virtualMachine?.spec?.template?.spec?.domain?.resources?.requests?.cpu ||
      virtualMachine?.spec?.template?.spec?.domain?.resources?.limits?.cpu
        ? 'requests'
        : 'topology'
    const showAdvancedOptions =
      dedicatedCpuPlacement ||
      virtualMachine?.spec?.template?.spec?.domain?.resources?.limits?.memory ||
      virtualMachine?.spec?.template?.spec?.domain?.resources?.requests?.memory
    return {
      showAdvancedOptions,
      vcpus: dedicatedCpuPlacement
        ? undefined
        : virtualMachine?.spec?.template?.spec?.domain?.cpu?.cores,
      ram: splitKubernetesUnitString(virtualMachine?.spec?.template?.spec?.domain?.memory?.guest)
        .number,
      ramUnit: splitKubernetesUnitString(
        virtualMachine?.spec?.template?.spec?.domain?.memory?.guest,
      ).unit,
      memoryRequests: splitKubernetesUnitString(
        virtualMachine?.spec?.template?.spec?.domain?.resources?.requests?.memory,
      ).number,
      memoryRequestsUnit: splitKubernetesUnitString(
        virtualMachine?.spec?.template?.spec?.domain?.resources?.requests?.memory,
      ).unit,
      memoryLimits: splitKubernetesUnitString(
        virtualMachine?.spec?.template?.spec?.domain?.resources?.limits?.memory,
      ).number,
      memoryLimitsUnit: splitKubernetesUnitString(
        virtualMachine?.spec?.template?.spec?.domain?.resources?.limits?.memory,
      ).unit,
      useCpuPinning: !!dedicatedCpuPlacement,
      configType,
      cpuRequests: virtualMachine?.spec?.template?.spec?.domain?.resources?.requests?.cpu,
      cpuLimits: virtualMachine?.spec?.template?.spec?.domain?.resources?.limits?.cpu,
      sockets: virtualMachine?.spec?.template?.spec?.domain?.cpu?.sockets,
      cores: virtualMachine?.spec?.template?.spec?.domain?.cpu?.cores,
      threads: virtualMachine?.spec?.template?.spec?.domain?.cpu?.threads,
    }
  }, [virtualMachine])

  const { params, updateParams, getParamsUpdater } = useParams<any>(defaultParams)
  const cpuRequestsLimitsValidator = useCallback(
    () =>
      customValidator((_, formValues) => {
        if (params.configType !== 'requests') {
          return true
        }
        // if cpu pinning is set, they must be equal if both are defined, otherwise just require one or the other
        if (formValues.useCpuPinning && formValues.cpuRequests && formValues.cpuLimits) {
          return formValues.cpuRequests === formValues.cpuLimits
        }
        return !!formValues.cpuRequests || !!formValues.cpuLimits
      }, 'Either CPU Requests or CPU Limits must be set. CPU Requests must equal CPU Limits when CPU pinning is enabled'),
    [params.showAdvancedOptions],
  )()

  const memoryRequestsLimitsValidator = useCallback(
    () =>
      customValidator(
        (_, formValues) => {
          // If cpu pinning is set, they must be equal if both are defined, otherwise just require one or the other
          if (formValues.useCpuPinning) {
            if (formValues.memoryRequests && formValues.memoryLimits) {
              return formValues.memoryRequests === formValues.memoryLimits
            }
            return !!formValues.memoryRequests || !!formValues.memoryLimits
          }
          // If cpu pinning is not set, then neither are required
          return !!formValues.ram || !!formValues.memoryRequests || !!formValues.memoryLimits
        },
        (value, formValues) => {
          if (formValues.useCpuPinning) {
            return 'Either Memory Requests or Memory Limits must be set. If both are set, they must be equal.'
          }
          return 'A Memory value must be set'
        },
      ),
    [params.showAdvancedOptions],
  )()

  const guestMemoryValidator = useCallback(
    () =>
      customValidator((value, formValues) => {
        if (params.showAdvancedOptions) {
          return !!value || !!formValues.memoryRequests || !!formValues.memoryLimits
        }
        return !!value
      }, 'A Memory value must be set'),
    [params.showAdvancedOptions],
  )()

  const submitForm = useCallback(async () => {
    const processBody = () => {
      // Explicitly set to undefined, don't want to set values of 0
      const cpu =
        params.configType === 'topology'
          ? {
              cores: params.useCpuPinning ? params.cores : params.vcpus,
              sockets: params.useCpuPinning ? params.sockets : undefined,
              threads: params.useCpuPinning ? params.threads : undefined,
              dedicatedCpuPlacement: params.useCpuPinning,
            }
          : params.useCpuPinning
          ? {
              dedicatedCpuPlacement: params.useCpuPinning,
            }
          : undefined
      const memory = !!params.ram
        ? {
            guest: `${params.ram}${params.ramUnit}`,
          }
        : undefined
      const hasCpuLimits =
        params.useCpuPinning && params.configType === 'requests' && !!params.cpuLimits
      const hasLimits = !!params.memoryLimits || hasCpuLimits
      const limits = hasLimits
        ? {
            cpu: hasCpuLimits ? params.cpuLimits : undefined,
            memory: !!params.memoryLimits
              ? `${params.memoryLimits}${params.memoryLimitsUnit}`
              : undefined,
          }
        : undefined
      const hasCpuRequests =
        params.useCpuPinning && params.configType === 'requests' && !!params.cpuRequests
      const hasRequests = !!params.memoryRequests || hasCpuRequests
      const requests = hasRequests
        ? {
            cpu: hasCpuRequests ? params.cpuRequests : undefined,
            memory: !!params.memoryRequests
              ? `${params.memoryRequests}${params.memoryRequestsUnit}`
              : undefined,
          }
        : undefined
      return {
        spec: {
          template: {
            spec: {
              domain: {
                cpu,
                memory,
                resources: {
                  limits,
                  requests,
                },
              },
            },
          },
        },
      }
    }
    const body = params?.showAdvancedOptions
      ? processBody()
      : {
          spec: {
            template: {
              spec: {
                domain: {
                  cpu: {
                    cores: params.vcpus,
                  },
                  memory: {
                    guest: `${params.ram}${params.ramUnit}`,
                  },
                },
              },
            },
          },
        }
    await updateFn({
      ...virtualMachine,
      body,
      requestType: 'patch',
    })
    // Check if there are any properties to remove in the other patch request
    const patchBody = getPatchOperations(virtualMachine, body)
    if (patchBody.length) {
      await updateFn({
        ...virtualMachine,
        body: patchBody,
        requestType: 'patch',
        contentType: 'application/json-patch+json',
      })
    }
    onClose(true)
  }, [virtualMachine, params])

  return (
    <>
      <DocumentMeta title="Edit Virtual Machine" bodyClasses={['form-view']} />
      <ModalForm
        open
        title="Edit Virtual Machine"
        onBackdropClick={noop}
        onClose={onClose}
        onSubmit={submitForm}
        submitTitle="Update Virtual Machine"
        submitting={updating}
        loadingMessage={updating ? 'Submitting form...' : 'Loading Virtual Machine...'}
      >
        <FormFieldSection title="Resources Settings">
          <Alert
            variant="primary"
            message={
              <Text variant="body2">
                <b>Note:</b> The VM needs to be restarted in order for these changes to take effect.
              </Text>
            }
          />
          <TextField
            id="vcpus"
            label="Cores"
            info="Number of cores visible inside the Guest OS"
            onChange={getParamsUpdater('vcpus')}
            value={params.vcpus}
            type="number"
            min={1}
            required={!params.useCpuPinning}
          />
          <div className={classes.withUnits}>
            <TextField
              id="ram"
              label="Memory"
              info="Amount of memory which is visible inside the Guest OS"
              onChange={getParamsUpdater('ram')}
              value={params.ram}
              type="number"
              min={0}
              required={!params.showAdvancedOptions}
              validations={[guestMemoryValidator]}
            />
            <UnitPicklist
              name="ramUnit"
              value={params.ramUnit}
              onChange={getParamsUpdater('ramUnit')}
              className={classes.unitsDropdown}
            />
          </div>
          <CheckboxField
            id="showAdvancedOptions"
            value={params.showAdvancedOptions}
            label="Advanced Options"
            onChange={getParamsUpdater('showAdvancedOptions')}
          />
          {params?.showAdvancedOptions && (
            <>
              <ToggleSwitchField
                id="useCpuPinning"
                label="CPU Pinning"
                onChange={getParamsUpdater('useCpuPinning')}
                value={params.useCpuPinning}
                info="Enables KubeVirt to pin guest's vCPUs to the host's pCPUs"
              />
              <Alert
                variant="primary"
                message={
                  <Text variant="body2">
                    <b>Note:</b> CPU Pinning should be the exception and not the rule and only be
                    enabled on VMs that require dedicated CPU resources.
                  </Text>
                }
              />
              <div className={classes.withUnits}>
                <TextField
                  id="memoryRequests"
                  label="Requests (Memory)"
                  info="The minimum amount of compute resources required"
                  onChange={getParamsUpdater('memoryRequests')}
                  value={params.memoryRequests}
                  type="number"
                  min={0}
                  validations={[memoryRequestsLimitsValidator]}
                />
                <UnitPicklist
                  name="memoryRequestsUnit"
                  value={params.memoryRequestsUnit}
                  onChange={getParamsUpdater('memoryRequestsUnit')}
                  className={classes.unitsDropdown}
                />
              </div>
              <div className={classes.withUnits}>
                <TextField
                  id="memoryLimits"
                  label="Limits (Memory)"
                  info="The maximum amount of compute resources allowed."
                  onChange={getParamsUpdater('memoryLimits')}
                  value={params.memoryLimits}
                  type="number"
                  min={0}
                  validations={[memoryRequestsLimitsValidator]}
                />
                <UnitPicklist
                  name="memoryLimitsUnit"
                  value={params.memoryLimitsUnit}
                  onChange={getParamsUpdater('memoryLimitsUnit')}
                  className={classes.unitsDropdown}
                />
              </div>
              <Text variant="caption1">
                Select one of the methods for configuring CPU resources for the VM
              </Text>
              <Radio
                label="Configure Topology"
                checked={params.configType === 'topology'}
                onChange={() => updateParams({ configType: 'topology' })}
              />
              <TextField
                id="sockets"
                label="Sockets"
                onChange={getParamsUpdater('sockets')}
                value={params.sockets}
                type="number"
                min={1}
                required={params.configType === 'topology'}
                disabled={params.configType !== 'topology'}
              />
              <TextField
                id="cores"
                label="Cores"
                onChange={getParamsUpdater('cores')}
                value={params.cores}
                type="number"
                min={1}
                required={params.configType === 'topology'}
                disabled={params.configType !== 'topology'}
              />
              <TextField
                id="threads"
                label="Threads"
                onChange={getParamsUpdater('threads')}
                value={params.threads}
                type="number"
                min={1}
                required={params.configType === 'topology'}
                disabled={params.configType !== 'topology'}
              />
              <Radio
                label="Configure Pod Resources"
                checked={params.configType === 'requests'}
                onChange={() => updateParams({ configType: 'requests' })}
              />
              <TextField
                id="cpuRequests"
                label="Requests (vCPUs)"
                info="The minimum amount of compute resources required"
                onChange={getParamsUpdater('cpuRequests')}
                value={params.cpuRequests}
                type="number"
                min={0}
                validations={[cpuRequestsLimitsValidator]}
                disabled={params.configType !== 'requests'}
              />
              <TextField
                id="cpuLimits"
                label="Limits (vCPUs)"
                info="The maximum amount of compute resources allowed."
                onChange={getParamsUpdater('cpuLimits')}
                value={params.cpuLimits}
                type="number"
                min={0}
                validations={[cpuRequestsLimitsValidator]}
                disabled={params.configType !== 'requests'}
              />
            </>
          )}
        </FormFieldSection>
      </ModalForm>
    </>
  )
}
