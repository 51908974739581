import React from 'react'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { findClusterName } from 'k8s/util/helpers'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { useSelector } from 'react-redux'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import Progress from 'core/components/progress/Progress'
import jsYaml from 'js-yaml'

const diskSourceLabels = {
  httpUrl: 'Image from HTTP URL',
  registryUrl: 'Image from Registry URL',
  disk: 'Image from Disk',
  clone: 'Clone PVC',
  volume: 'Attach Data Volume',
  existing: ' Existing PVC',
}

const diskSourceValues = {
  httpUrl: (disk) => {
    return `: ${disk.httpUrl}`
  },
  registryUrl: (disk) => {
    return `: ${disk.registryUrl}`
  },
  disk: (disk) => {
    return ''
  },
  clone: (disk) => {
    return `: ${disk.selectedPvcs?.[0]?.name}`
  },
  volume: (disk) => {
    return `: ${disk.selectedDataVolumes?.[0]?.name}`
  },
  existing: (disk) => {
    return `: ${disk.selectedPvcs?.[0]?.name}`
  },
}

const RenderClusterName = ({ clusterId }) => {
  const { loading } = useListAction(listClusters)
  const clusters = useSelector(clustersSelector)
  const clusterName = findClusterName(clusters, clusterId)
  return (
    <Progress inline loading={loading} overlay={false} message={null}>
      <span>{clusterName}</span>
    </Progress>
  )
}

const RenderBulkVmNames = ({ names }) => {
  return (
    <>
      {names.map((name) => (
        <div key={name}>{name}</div>
      ))}
    </>
  )
}

const RenderStorageDisks = ({ storageDisks }) => {
  return (
    <>
      {storageDisks.map((disk, idx) => (
        <div key={disk.rowId}>
          {idx + 1}. {diskSourceLabels[disk.sourceType]}
          {diskSourceValues[disk.sourceType](disk)}
        </div>
      ))}
    </>
  )
}

const RenderNetworks = ({ networks }) => {
  return (
    <>
      {networks.map((network) => (
        <div key={network.name}>{network.name}</div>
      ))}
    </>
  )
}

const columns = [
  { id: 'metadata.name', label: 'VM Instance Name' },
  {
    id: 'vmNames',
    label: 'Bulk VM Names',
    renderArray: true,
    render: (value) => <RenderBulkVmNames names={value} />,
    hide: (wizardContext) => !wizardContext.createMultiple,
  },
  { id: 'clusterId', label: 'Cluster', render: (value) => <RenderClusterName clusterId={value} /> },
  { id: 'namespace', label: 'Namespace' },
  { id: 'kind', label: 'VM Type' },
  {
    id: 'spec.runStrategy',
    label: 'Run Strategy',
    hide: (wizardContext) => wizardContext.vmType === 'VirtualMachineInstance',
  },
  {
    id: 'storageDisks',
    label: 'Storage Sources',
    renderArray: true,
    render: (value) => <RenderStorageDisks storageDisks={value} />,
    insertDivider: true,
  },
  {
    id: 'instanceType',
    label: 'Instancetype',
    renderArray: true,
    render: (value) => value?.[0]?.name,
    hide: (wizardContext) => wizardContext.resourceOption === 'custom',
    insertDivider: true,
  },
  {
    id: 'spec.template.spec.domain.cpu.cores',
    label: 'VCPUs',
    hide: (wizardContext) =>
      wizardContext.vmType === 'VirtualMachineInstance' ||
      wizardContext.resourceOption === 'useInstanceType',
    insertDivider: true,
  },
  {
    id: 'spec.template.spec.domain.memory.guest',
    label: 'RAM',
    hide: (wizardContext) =>
      wizardContext.vmType === 'VirtualMachineInstance' ||
      wizardContext.resourceOption === 'useInstanceType',
  },
  {
    id: 'spec.domain.cpu.cores',
    label: 'VCPUs',
    hide: (wizardContext) =>
      wizardContext.vmType === 'VirtualMachine' ||
      wizardContext.resourceOption === 'useInstanceType',
    insertDivider: true,
  },
  {
    id: 'spec.domain.memory.guest',
    label: 'RAM',
    hide: (wizardContext) =>
      wizardContext.vmType === 'VirtualMachine' ||
      wizardContext.resourceOption === 'useInstanceType',
  },
  {
    id: 'podNetworkBindingType',
    label: 'Pod Network Binding Type',
    insertDivider: true,
  },
  {
    id: 'selectedNetworks',
    label: 'Networks',
    renderArray: true,
    render: (value) => <RenderNetworks networks={value} />,
  },
  {
    id: 'cloudInit',
    label: 'Cloud-init Script',
    render: (value) => (value === '-' ? 'no' : 'yes'),
    insertDivider: true,
  },
]

const ReviewStep = ({ wizardContext }) => {
  const yamlJson = jsYaml.load(wizardContext.advancedYaml)
  const combinedJson = {
    ...wizardContext,
    ...yamlJson,
  }
  return (
    <FormFieldSection title="Finalize & Review">
      <FormReviewTable data={combinedJson} columns={columns} />
    </FormFieldSection>
  )
}

export default ReviewStep
