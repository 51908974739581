import Plugin from 'core/plugins/plugin'
import { AppPlugins } from 'app/constants'
import { kubevirtLogo } from './logo'
import VirtualMachineDetailPage from './components/virtual-machines/details'
import AddVirtualMachinePage from './components/virtual-machines/add/AddVirtualMachineForm'
import EditVirtualMachinePage from './components/virtual-machines/EditVmPage'
import VmOverviewPage from './components/virtual-machines'
import VirtualMachineDetailsPage from './components/virtual-machines/vm-details/VirtualMachineDetailsPage'
import VirtualMachineInstanceDetailsPage from './components/virtual-machines/vmi-details/VirtualMachineInstanceDetailsPage'
import LiveMigrationsOverview from './components/virtual-machines/live-migrations/LiveMigrationsOverview'
import KubevirtDashboard from './components/dashboard/KubevirtDashboard'
import InstanceTypesListPage from './components/virtual-machines/instance-types/InstanceTypesListPage'
import CreateInstanceTypePage from './components/virtual-machines/instance-types/CreateInstanceTypePage'
import KubevirtEventsPage from './components/events/KubevirtEventsPage'

const kubevirtPlugin = new Plugin(AppPlugins.KubeVirt, 'KubeVirt', '/ui/kubevirt', kubevirtLogo)
// kubevirtPlugin.setOption('singlePane', true)

kubevirtPlugin.registerRoutes([
  {
    name: 'Kubevirt Dashboard',
    link: {
      path: '/dashboard',
      exact: true,
      default: true,
    },
    requiredRoles: 'admin',
    component: KubevirtDashboard,
  },
  {
    name: 'Virtual Machines',
    link: {
      path: '/virtual-machines/overview',
      exact: true,
    },
    requiredRoles: 'admin',
    component: VmOverviewPage,
  },
  {
    name: 'Virtual Machine Details',
    link: { path: '/virtual-machines/clusters/:clusterId/virtual-machines/:id/:tab?', exact: true },
    component: VirtualMachineDetailsPage,
    requiredRoles: 'admin',
  },
  {
    name: 'Virtual Machine Instance Details',
    link: {
      path: '/virtual-machines/clusters/:clusterId/virtual-machine-instances/:name/:tab?',
      exact: true,
    },
    component: VirtualMachineInstanceDetailsPage,
    requiredRoles: 'admin',
  },
  {
    name: 'Add Virtual Machine',
    link: { path: '/virtual-machines/add/new', exact: true },
    requiredRoles: 'admin',
    component: AddVirtualMachinePage,
  },
  {
    name: 'Add Virtual Machine',
    link: { path: '/virtual-machines/import/url', exact: true },
    requiredRoles: 'admin',
    component: AddVirtualMachinePage,
  },
  {
    name: 'Add Virtual Machine',
    link: { path: '/virtual-machines/import/disk', exact: true },
    requiredRoles: 'admin',
    component: AddVirtualMachinePage,
  },
  {
    name: 'Add Virtual Machine',
    link: { path: '/virtual-machines/clone/pvc', exact: true },
    requiredRoles: 'admin',
    component: AddVirtualMachinePage,
  },
  {
    name: 'Edit Virtual Machine',
    link: { path: '/virtual-machines/clusters/:clusterId/vms/edit/:id', exact: true },
    component: EditVirtualMachinePage,
  },
  {
    name: 'Virtual Machine Details',
    link: { path: '/virtual-machines/:clusterId/:namespace/:name', exact: true },
    requiredRoles: 'admin',
    component: VirtualMachineDetailPage,
  },
  {
    name: 'Live Migrations',
    link: { path: '/virtual-machines/live-migrations', exact: true },
    requiredRoles: 'admin',
    component: LiveMigrationsOverview,
  },
  {
    name: 'Instancetypes',
    link: { path: '/virtual-machines/instance-types', exact: true },
    requiredRoles: 'admin',
    component: InstanceTypesListPage,
  },
  {
    name: 'Create Instancetype',
    link: { path: '/virtual-machines/instance-types/new', exact: true },
    requiredRoles: 'admin',
    component: CreateInstanceTypePage,
  },
  {
    name: 'Events',
    link: { path: '/events', exact: true },
    requiredRoles: 'admin',
    component: KubevirtEventsPage,
  },
])

const navItems = [
  {
    name: 'Dashboard',
    icon: 'tachometer',
    link: { path: '/dashboard', definition: '/dashboard' },
  },
  {
    name: 'Virtual Machines',
    icon: 'hard-drive',
    link: { path: '/virtual-machines/overview', definition: '/virtual-machines/:page' },
    nestedLinks: [
      { name: 'All VMs', link: { path: 'virtual-machines/overview' } },
      { name: 'Live Migrations', link: { path: 'virtual-machines/live-migrations' } },
      { name: 'Instancetypes', link: { path: 'virtual-machines/instance-types' } },
    ],
  },
  {
    name: 'Events',
    icon: 'analytics',
    link: { path: '/events', definition: '/events' },
  },
]

kubevirtPlugin.registerNavItems(navItems)

export default kubevirtPlugin
