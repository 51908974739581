import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { flatten } from 'ramda'
import jsYaml from 'js-yaml'
import { someAsync } from 'utils/async'
import { trackEvent } from 'utils/tracking'
import createContextLoader from 'core/helpers/createContextLoader'
import { vmVolumeTypes, VMVolumeTypes, Volume } from './model'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import { cacheActions } from 'core/caching/cacheReducers'
import { addonTypeToNameMap } from 'app/plugins/infrastructure/components/clusters/cluster-addons/helpers'
import store from 'app/store'
import { ClusterAddonType } from 'app/plugins/infrastructure/components/clusters/cluster-addons/model'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { dispatch } = store

const { qbert } = ApiClient.getInstance()

const volumeSpecApisByType = {
  [VMVolumeTypes.CloudInitNoCloud]: null,
  [VMVolumeTypes.CloudInitConfigDrive]: null,
  [VMVolumeTypes.PersistentVolumeClaim]: null,
  [VMVolumeTypes.DataVolume]: qbert.getVirtualMachineVolumeDetails,
  [VMVolumeTypes.Ephemeral]: null,
  [VMVolumeTypes.ContainerDisk]: null,
  [VMVolumeTypes.EmptyDisk]: null,
  [VMVolumeTypes.HostDisk]: null,
  [VMVolumeTypes.ConfigMap]: null,
  [VMVolumeTypes.Secret]: null,
  [VMVolumeTypes.ServiceAccount]: null,
}
export const findVolumeSpecPromises = (clusterId, namespace, volumes: Volume[] = []) => {
  const promises = []
  volumes.forEach((volume) => {
    vmVolumeTypes.forEach((vmType) => {
      if (volume.hasOwnProperty(vmType)) {
        volume.vmType = vmType
      }
    })
    if (volume.vmType && volumeSpecApisByType[volume.vmType]) {
      // make request and pass the volume name through
      promises.push(
        volumeSpecApisByType[volume.vmType](
          clusterId,
          namespace,
          volume.vmType,
          volume[volume.vmType]?.name,
        ),
      )
    }
  })
  return promises
}

// This below function most likely will not be necessary I think
// TODO: Update this after the GetOneAction Xan is working on is done
export const virtualMachineDetailsLoader = createContextLoader(
  DataKeys.VirtualMachineDetails,
  async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to load virtual machine details', {
      clusterId,
      namespace,
      name,
    })
    const vm = await qbert.getVirtualMachineInstanceByName(clusterId, namespace, name)
    const disks = vm?.spec?.domain?.devices?.disks || []
    const volumes = vm?.spec?.volumes || []
    const volumesByDisk = disks.map((disk) => volumes.find((volume) => volume.name === disk.name))
    const volumePromises = findVolumeSpecPromises(clusterId, namespace, volumesByDisk)
    const volumeDetails = await Promise.all(volumePromises)
    // const vmFs = await qbert.getVirtualMachineFileSystemList(clusterId, namespace, name)
    return { ...vm, clusterId, namespace, name, volumeDetails }
  },
  {
    entityName: 'Virtual Machine Detail',
    uniqueIdentifier: 'metadata.uid',
    indexBy: ['clusterId', 'namespace', 'name'],
  },
)

export const virtualMachineInstanceActions = ActionsSet.make<DataKeys.VirtualMachineInstances>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.VirtualMachineInstances,
  cacheKey: DataKeys.VirtualMachineInstances,
})

export const listVirtualMachineInstances = virtualMachineInstanceActions.add(
  new ListAction<DataKeys.VirtualMachineInstances, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get VM instances', params)
    return qbert.getVirtualMachineInstances(params.clusterId)
  })
    .addDependency(DataKeys.VirtualMachineInstances)
    .addDependency(DataKeys.IpAllocations),
)

export const createVirtualMachineInstance = virtualMachineInstanceActions.add(
  new CreateAction<
    DataKeys.VirtualMachineInstances,
    { clusterId: string; namespace: string; yaml: string }
  >(async ({ clusterId, namespace, yaml }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new VM instance', { clusterId, namespace, yaml })
    const body = jsYaml.load(yaml)
    const vm: any = await qbert.createVirtualMachineInstance(clusterId, namespace, body)
    trackEvent('Create New VM instance', {
      clusterId,
      namespace,
      name: vm?.metadata?.name,
    })
    return vm
  }),
)

export const updateVirtualMachineInstance = virtualMachineInstanceActions.add(
  new UpdateAction<
    DataKeys.VirtualMachineInstances,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Virtual Machine Instance', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn =
      requestType === 'patch'
        ? qbert.patchVirtualMachineInstance
        : qbert.updateVirtualMachineInstance
    const updatedVmi = await updateFn({ clusterId, namespace, name, body, contentType })
    trackEvent('Update Virtual Machine Instance', { clusterId, namespace, name })
    return updatedVmi
  }),
)

export const deleteVirtualMachineInstance = virtualMachineInstanceActions.add(
  new DeleteAction<
    DataKeys.VirtualMachineInstances,
    { clusterId: string; namespace: string; name: string }
  >(async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete VM instance', { clusterId, namespace, name })
    await qbert.deleteVirtualMachineInstance(clusterId, namespace, name)
  }),
)

export const powerVirtualMachineInstance = virtualMachineInstanceActions.add(
  new CustomAction<
    DataKeys.VirtualMachineInstances,
    { clusterId: string; namespace: string; name: string; operation: string; id: string }
  >(
    'vmiPowerOperation',
    async ({ clusterId, namespace, name, operation }) => {
      Bugsnag.leaveBreadcrumb('Attempting to use power operation on VMI', {
        clusterId,
        namespace,
        name,
        operation,
      })
      const vmi = await qbert.vmiPowerOperation(clusterId, namespace, name, operation)
      trackEvent('Power operation on VMI', {
        clusterId,
        namespace,
        name,
        operation,
      })
      return vmi
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'metadata.uid',
          cacheKey: DataKeys.VirtualMachineInstances,
          params: { metadata: { uid: id } }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

// Technically this creates a VirtualMachineInstanceMigration object, but
// maybe we can abstract that away and use CustomAction
export const migrateVirtualMachineInstance = virtualMachineInstanceActions.add(
  new CustomAction<
    DataKeys.VirtualMachineInstances,
    { clusterId: string; namespace: string; vmiName: string; id: string }
  >(
    'migrateVmi',
    async ({ clusterId, namespace, vmiName }) => {
      Bugsnag.leaveBreadcrumb('Attempting to migrate VMI', {
        clusterId,
        namespace,
        name: vmiName,
      })
      const timestamp = new Date().getTime()
      const body = {
        apiVersion: 'kubevirt.io/v1',
        kind: 'VirtualMachineInstanceMigration',
        metadata: {
          name: `${vmiName}-${timestamp}`,
        },
        spec: {
          vmiName,
        },
      }
      const response = await qbert.virtualMachineInstanceMigration(clusterId, namespace, body)
      trackEvent('Power operation on VMI', {
        clusterId,
        namespace,
        name: vmiName,
      })
      listLiveMigrations.call({ clusterId })
      return response
    },
    (result, { id }) => {
      // Update the vmi in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'metadata.uid',
          cacheKey: DataKeys.VirtualMachineInstances,
          params: { metadata: { uid: id } }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const virtualMachineActions = ActionsSet.make<DataKeys.VirtualMachines>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.VirtualMachines,
  cacheKey: DataKeys.VirtualMachines,
})

export const listVirtualMachines = virtualMachineActions.add(
  new ListAction<DataKeys.VirtualMachines, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get VMs', params)
    return qbert.getVirtualMachines(params.clusterId)
  })
    .addDependency(DataKeys.VirtualMachineInstances)
    .addDependency(DataKeys.InstanceTypes)
    .addDependency(DataKeys.ClusterInstanceTypes),
)

export const createVirtualMachine = virtualMachineActions.add(
  new CreateAction<
    DataKeys.VirtualMachines,
    { clusterId: string; namespace: string; yaml: string }
  >(async ({ clusterId, namespace, yaml }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new VM', { clusterId, namespace, yaml })
    const body = jsYaml.load(yaml)
    const vm: any = await qbert.createVirtualMachine(clusterId, namespace, body)
    trackEvent('Create New VM', {
      clusterId,
      namespace,
      name: vm?.metadata?.name,
    })
    return vm
  }),
)

export const updateVirtualMachine = virtualMachineActions.add(
  new UpdateAction<
    DataKeys.VirtualMachines,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Virtual Machine', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn =
      requestType === 'patch' ? qbert.patchVirtualMachine : qbert.updateVirtualMachine
    const updatedVm = await updateFn({ clusterId, namespace, name, body, contentType })
    trackEvent('Update Virtual Machine', { clusterId, namespace, name })
    return updatedVm
  }),
)

export const deleteVirtualMachine = virtualMachineActions.add(
  new DeleteAction<
    DataKeys.VirtualMachines,
    { clusterId: string; namespace: string; name: string }
  >(async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete VM', { clusterId, namespace, name })
    await qbert.deleteVirtualMachine(clusterId, namespace, name)
  }),
)

export const powerVirtualMachine = virtualMachineActions.add(
  new CustomAction<
    DataKeys.VirtualMachines,
    { clusterId: string; namespace: string; name: string; operation: string; id: string }
  >(
    'vmPowerOperation',
    async ({ clusterId, namespace, name, operation }) => {
      Bugsnag.leaveBreadcrumb('Attempting to use power operation on VM', {
        clusterId,
        namespace,
        name,
        operation,
      })
      const vm = await qbert.virtualMachinePowerOperation(clusterId, namespace, name, operation)
      trackEvent('Power operation on VM', {
        clusterId,
        namespace,
        name,
        operation,
      })
      return vm
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'metadata.uid',
          cacheKey: DataKeys.VirtualMachines,
          params: { metadata: { uid: id } }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const virtualMachinePresetActions = ActionsSet.make<DataKeys.VirtualMachinePresets>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey.VirtualMachinePresets,
  cacheKey: DataKeys.VirtualMachinePresets,
})

export const listVirtualMachinePresets = virtualMachinePresetActions.add(
  new ListAction<DataKeys.VirtualMachinePresets, { clusterId: string; namespace: string }>(
    async ({ clusterId, namespace }) => {
      Bugsnag.leaveBreadcrumb('Attempting to load virtual machine presets', {
        clusterId,
        namespace,
      })
      return qbert.getVirtualMachinePresets(clusterId, namespace)
    },
  ),
)

export const networkActions = ActionsSet.make<DataKeys.Networks>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey.Networks,
  cacheKey: DataKeys.Networks,
})

export const listNetworks = networkActions.add(
  new ListAction<DataKeys.Networks, { clusterId: string; namespace: string }>(
    async ({ clusterId, namespace }) => {
      Bugsnag.leaveBreadcrumb('Attempting to load networks', {
        clusterId,
        namespace,
      })
      return qbert.getNetworks(clusterId, namespace)
    },
  ),
)

export const enableKubevirt = async (clusterId, luigiOperatorEnabled, enableAddonFn) => {
  Bugsnag.leaveBreadcrumb('Attempting to get addon versions')
  const currentAddonVersions = (await qbert.getAddonVersions(clusterId))?.[0] || {}
  const kubevirtVersion = currentAddonVersions[addonTypeToNameMap[ClusterAddonType.Kubevirt]]
  const luigiVersion = currentAddonVersions[addonTypeToNameMap[ClusterAddonType.Luigi]]

  let enablingLuigiSuccess = false

  if (!luigiOperatorEnabled) {
    const { success } = await enableAddonFn({
      clusterId,
      type: ClusterAddonType.Luigi,
      version: luigiVersion,
      overrideParams: {},
    })

    enablingLuigiSuccess = success
  }

  const { success: enablingKubevirtSuccess } = await enableAddonFn({
    clusterId,
    type: ClusterAddonType.Kubevirt,
    version: kubevirtVersion,
    overrideParams: {},
  })

  return enablingLuigiSuccess || enablingKubevirtSuccess
}

export const liveMigrationActions = ActionsSet.make<DataKeys.LiveMigrations>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.LiveMigrations,
  cacheKey: DataKeys.LiveMigrations,
})

export const listLiveMigrations = liveMigrationActions.add(
  new ListAction<DataKeys.LiveMigrations, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Live Migrations', params)
    return qbert.getLiveMigrations(params.clusterId)
  })
    .addDependency(DataKeys.VirtualMachines)
    .addDependency(DataKeys.VirtualMachineInstances),
)

export const ipAllocationActions = ActionsSet.make<DataKeys.IpAllocations>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.IpAllocations,
  cacheKey: DataKeys.IpAllocations,
})

export const listIpAllocations = ipAllocationActions.add(
  new ListAction<DataKeys.IpAllocations, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get IP Allocations', params)
    return qbert.getIpAllocations(params.clusterId)
  }),
)
