import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { updateInstanceType, updateClusterInstanceType } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import DocumentMeta from 'core/components/DocumentMeta'
import ModalForm from 'core/elements/modal/ModalForm'
import { noop } from 'utils/fp'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import useParams from 'core/hooks/useParams'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import UnitPicklist from '../add/UnitPicklist'
import { splitKubernetesUnitString } from '../vm-details/helpers'
import PicklistField from 'core/components/validatedForm/DropdownField'
import HugePagesPicklist from './HugePagesPicklist'

const useStyles = makeStyles<Theme>((theme) => ({
  advancedOptionsLabel: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
}))

export default function EditInstanceTypeDialog({ rows: [instanceType], onClose }) {
  const classes = useStyles()
  const { update: updateInstanceTypeFn, updating: updatingInstanceType } = useUpdateAction(
    updateInstanceType,
  )
  const {
    update: updateClusterInstanceTypeFn,
    updating: updatingClusterInstanceType,
  } = useUpdateAction(updateClusterInstanceType)
  const updateFnMap = {
    VirtualMachineInstancetype: updateInstanceTypeFn,
    VirtualMachineClusterInstancetype: updateClusterInstanceTypeFn,
  }
  const defaultParams = useMemo(() => {
    return {
      // dedicatedCpuPlacement: !!instanceType?.spec?.cpu?.dedicatedCPUPlacement,
      vcpus: instanceType?.spec?.cpu?.guest,
      memory: splitKubernetesUnitString(instanceType?.spec?.memory?.guest).number,
      memoryUnit: splitKubernetesUnitString(instanceType?.spec?.memory?.guest).unit,
      // hugePages: instanceType?.spec?.memory?.hugepages?.pageSize,
    }
  }, [instanceType])

  const { params, getParamsUpdater } = useParams<any>(defaultParams)

  const submitForm = useCallback(async () => {
    const body = {
      ...instanceType,
      spec: {
        ...instanceType.spec,
        cpu: {
          ...instanceType?.spec?.cpu,
          guest: params?.vcpus,
          // dedicatedCPUPlacement: params.dedicatedCpuPlacement,
        },
        memory: {
          ...instanceType?.spec?.memory,
          guest: `${params?.memory}${params?.memoryUnit}`,
          // hugepages: params?.hugePages ? { pageSize: params.hugePages } : undefined,
        },
      },
    }
    const updateFn = updateFnMap[instanceType?.kind]
    await updateFn({
      ...instanceType,
      body,
    })
    onClose(true)
  }, [instanceType, params])

  return (
    <>
      <DocumentMeta title="Edit Instancetype" bodyClasses={['form-view']} />
      <ModalForm
        open
        title={`Edit Instancetype "${instanceType.name}"`}
        onBackdropClick={noop}
        onClose={onClose}
        onSubmit={submitForm}
        submitTitle="Update Instancetype"
        submitting={updatingInstanceType || updatingClusterInstanceType}
        loadingMessage={
          updatingInstanceType || updatingClusterInstanceType
            ? 'Submitting form...'
            : 'Loading Instancetype...'
        }
      >
        <FormFieldSection title="Resources">
          <TextField
            id="vcpus"
            label="vCPUs"
            onChange={getParamsUpdater('vcpus')}
            value={params.vcpus}
            type="number"
            min="1"
            required
          />
          {/* <ToggleSwitchField
            id="dedicatedCpuPlacement"
            label="Dedicated CPU Placement"
            onChange={getParamsUpdater('dedicatedCpuPlacement')}
            value={params.dedicatedCpuPlacement}
          /> */}
          <div className={classes.withUnits}>
            <TextField
              id="memory"
              label="Guest Memory"
              onChange={getParamsUpdater('memory')}
              value={params.memory}
              type="number"
              min="1"
              required
            />
            <UnitPicklist
              name="memoryUnit"
              value={params.memoryUnit}
              onChange={getParamsUpdater('memoryUnit')}
              className={classes.unitsDropdown}
            />
          </div>
          {/* <PicklistField
            DropdownComponent={HugePagesPicklist}
            id="hugePages"
            label="Huge Pages (optional)"
            value={params.hugePages}
            onChange={getParamsUpdater('hugePages')}
          /> */}
        </FormFieldSection>
      </ModalForm>
    </>
  )
}
