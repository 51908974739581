import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import { updateVirtualMachine } from '../new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import DocumentMeta from 'core/components/DocumentMeta'
import ModalForm from 'core/elements/modal/ModalForm'
import { noop } from 'utils/fp'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useParams from 'core/hooks/useParams'
import Alert from 'core/components/Alert'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { allInstanceTypesSelector } from '../instance-types/selectors'
import { listInstanceTypes, listClusterInstanceTypes } from '../instance-types/actions'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { IInstanceTypeSelector } from '../instance-types/instance-types-model'
import ApiClient from 'api-client/ApiClient'

const { qbert } = ApiClient.getInstance()

const columns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'spec.cpu.guest',
    label: 'vCPUs',
  },
  {
    id: 'spec.memory.guest',
    label: 'Memory',
  },
  {
    id: 'spec.cpu.dedicatedCPUPlacement',
    label: 'Dedicated CPU Placement',
    render: (val: boolean) => (val ? 'Enabled' : 'Disabled'),
  },
  {
    id: 'spec.memory.hugepages.pageSize',
    label: 'Huge Pages',
    render: (val: string) => (val ? val : 'N/A'),
  },
  {
    id: 'namespace',
    label: 'Scope',
    render: (val: string) => (val ? `Namespace: ${val}` : 'Cluster Scoped'),
  },
]

const defaultParams = {
  instanceType: [],
}

export default function ChangeVmInstanceTypeDialog({ rows: [virtualMachine], onClose }) {
  const { update: updateFn, updating } = useUpdateAction(updateVirtualMachine)
  const [vmJson, setVmJson] = useState({})
  const [loadingJson, setLoadingJson] = useState(true)

  useEffect(() => {
    const getVmJson = async () => {
      const json = await qbert.getVirtualMachineByName(
        virtualMachine.clusterId,
        virtualMachine.namespace,
        virtualMachine.name,
      )
      setVmJson(json)
      setLoadingJson(false)
    }
    getVmJson()
  }, [virtualMachine])

  const { loading: loadingInstanceTypes } = useListAction(listInstanceTypes, {
    params: {
      clusterId: virtualMachine.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const { loading: loadingClusterInstanceTypes } = useListAction(listClusterInstanceTypes, {
    params: {
      clusterId: virtualMachine.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const instanceTypes = useSelectorWithParams(allInstanceTypesSelector, {
    clusterId: virtualMachine.clusterId,
    // currently will never filter by namespace unless using global params
    // namespace: virtualMachine.namespace,
    useGlobalParams: false,
  })
  // Explicit typing to get around IClusterInstanceType not having namespace
  const namespaceInstanceTypes = useMemo(() => {
    return instanceTypes.filter((instanceType: IInstanceTypeSelector) => {
      if (instanceType?.namespace) {
        return instanceType.namespace === virtualMachine.namespace
      }
      return true
    })
  }, instanceTypes)

  const { params, updateParams, getParamsUpdater } = useParams<any>(defaultParams)

  useEffect(() => {
    const instanceType = namespaceInstanceTypes?.find((instanceType) => {
      return (
        instanceType?.kind === virtualMachine?.spec?.instancetype?.kind &&
        instanceType?.name === virtualMachine?.spec?.instancetype?.name
      )
    })
    updateParams({ instanceType: instanceType ? [instanceType] : [] })
  }, [namespaceInstanceTypes])

  const submitForm = useCallback(async () => {
    const body = {
      ...vmJson,
      spec: {
        // @ts-ignore
        ...vmJson?.spec,
        instancetype: {
          kind: params?.instanceType?.[0]?.kind,
          name: params?.instanceType?.[0]?.name,
        },
      },
    }
    await updateFn({
      ...virtualMachine,
      body,
    })
    onClose(true)
  }, [virtualMachine, vmJson, params])

  return (
    <>
      <DocumentMeta title="Edit Virtual Machine" bodyClasses={['form-view']} />
      <ModalForm
        open
        title="Edit Virtual Machine"
        onBackdropClick={noop}
        onClose={onClose}
        onSubmit={submitForm}
        submitTitle="Update Virtual Machine"
        loading={loadingJson}
        submitting={updating}
        loadingMessage={updating ? 'Submitting form...' : 'Loading Virtual Machine...'}
      >
        <FormFieldSection title="Select an Instancetype">
          <Alert
            variant="primary"
            message={
              <Text variant="body2">
                <b>Note:</b> The VM needs to be restarted in order for these changes to take effect.
              </Text>
            }
          />
          <ListTableField
            id="instanceType"
            data={namespaceInstanceTypes}
            loading={loadingInstanceTypes || loadingClusterInstanceTypes}
            columns={columns}
            onChange={getParamsUpdater('instanceType')}
            value={params?.instanceType}
            required
          />
        </FormFieldSection>
      </ModalForm>
    </>
  )
}
