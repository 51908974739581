import React from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import PodNetworkBindingPicklist from './PodNetworkBindingPicklist'
import { listNetworks } from '../new-actions'
import { networksSelector } from '../selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import PicklistField from 'core/components/validatedForm/DropdownField'

const useStyles = makeStyles<Theme>((theme) => ({
  bindingModeField: {
    marginBottom: theme.spacing(3),
  },
  label: {
    marginRight: theme.spacing(3),
  },
}))

const renderBindingMode = (_) => <>bridge</>

const columns = [
  { id: 'name', label: 'Network' },
  { id: 'kind', label: 'Binding Mode', render: renderBindingMode },
]

export const NetworkStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})

  const { loading, reload: reloadNetworks } = useListAction(listNetworks, {
    params: {
      clusterId: wizardContext.clusterId,
      namespace: wizardContext.namespace,
    },
    requiredParams: ['clusterId', 'namespace'],
  })
  const networks = useSelectorWithParams(networksSelector, {
    clusterId: wizardContext.clusterId,
    namespace: wizardContext.namespace,
  })

  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={onNext}
      elevated={false}
    >
      <FormFieldSection title="Network Selection">
        <Text variant="body2">
          <b>NOTE</b>: Live migration is not allowed with a pod network binding of <i>bridge</i>{' '}
          interface type
        </Text>
        <div className={classes.bindingModeField}>
          <PicklistField
            DropdownComponent={PodNetworkBindingPicklist}
            id="podNetworkBindingType"
            onChange={(value) => setWizardContext({ podNetworkBindingType: value })}
            value={wizardContext.podNetworkBindingType}
            label="Binding Mode for Pod Network"
            required
          />
        </div>
        <Text variant="body2">Select Network(s)</Text>
        <ListTableField
          id="selectedNetworks"
          data={networks}
          loading={loading}
          columns={columns}
          onChange={(value) => setWizardContext({ selectedNetworks: value })}
          value={wizardContext.selectedNetworks}
          onReload={() => {
            reloadNetworks(true, true)
          }}
          multiSelection
        />
      </FormFieldSection>
    </ValidatedForm>
  )
}

export default NetworkStep
