import React, { useEffect, useMemo } from 'react'
import Button from 'core/elements/button'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ExternalLink from 'core/components/ExternalLink'
import { kubevirtDocumentationLink, tutorialsLink, vmsLink, vmisLink } from '../../links'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { listVirtualMachines, listVirtualMachineInstances } from '../virtual-machines/new-actions'
import { useSelector } from 'react-redux'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { ClusterSelector } from 'app/plugins/infrastructure/components/clusters/model'
import Card from 'core/elements/card'
import StatCard from './StatCard'
import useParams from 'core/hooks/useParams'
import { kubevirtAddonsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import { listStorageClasses } from 'k8s/components/storage/storage-classes/new-actions'
import { listPersistentVolumes } from 'k8s/components/storage/persistent-volume/new-actions'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { PieDataEntry } from 'core/components/graphs/PieGraph'
import DonutWidget from 'core/components/widgets/DonutWidget'
import { kubevirtDashboardResourcesSelector } from './selectors'
import CardHeader from 'core/elements/card/CardHeader'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'

const useStyles = makeStyles<Theme>((theme) => ({
  docLinks: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: 8,
  },
  topCards: {
    marginTop: 24,
    display: 'grid',
    gap: 8,
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  properties: {
    display: 'inline-grid',
    gap: 32,
    gridAutoFlow: 'column',
  },
  property: {
    display: 'grid',
    gap: 4,
    padding: 24,
  },
  normalWeight: {
    fontWeight: 'normal',
  },
  bottomCards: {
    marginTop: 16,
    display: 'grid',
    gap: 8,
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'start',
  },
  donutContainer: {
    padding: 24,
  },
  cardLink: {
    '& > span': {
      color: theme.components.typography.active,
    },
  },
}))

type Params = {
  clusterId: string | string[]
}

const defaultParams: Params = {
  clusterId: '',
}

const DocLinks = () => {
  const classes = useStyles()
  return (
    <div className={classes.docLinks}>
      <ExternalLink url={kubevirtDocumentationLink}>
        <Button rightIcon="arrow-up-right-from-square" variant="secondary">
          Read Documentation
        </Button>
      </ExternalLink>
      <ExternalLink url={tutorialsLink}>
        <Button rightIcon="arrow-up-right-from-square" variant="secondary">
          Watch Tutorials
        </Button>
      </ExternalLink>
    </div>
  )
}

const allClustersParams = {
  orderBy: 'name',
  kubevirtClusters: true,
}

const healthyClustersParams = {
  orderBy: 'name',
  kubevirtClusters: true,
  healthyClusters: true,
  ecoEnabledClusters: true,
}

const defaultParamsAllClusterSelector = makeParamsAllClustersSelector(allClustersParams)
const defaultParamsHealthyClusterSelector = makeParamsAllClustersSelector(healthyClustersParams)

const noPieData = [{ name: 'unknown', value: 1, color: 'tray' }]

export default function KubevirtDashboard() {
  const classes = useStyles()
  const { params, updateParams } = useParams(defaultParams)
  const { loading: clustersLoading } = useListAction(listClusters)
  const { loading: importedClustersLoading } = useListAction(listImportedClusters)
  const allClusters = useSelector(defaultParamsAllClusterSelector)
  const healthyClusters = useSelector(defaultParamsHealthyClusterSelector)
  const allNodes = useMemo(() => {
    return allClusters.reduce((accum, cluster: ClusterSelector) => {
      const clusterNodes = cluster?.nodes || []
      return [...accum, ...clusterNodes]
    }, [])
  }, [allClusters])

  useEffect(() => {
    // Clusters need to be loaded up before other list actions are made, otherwise,
    // won't be able to get the cluster IDs
    if (!clustersLoading && !importedClustersLoading) {
      const clusterIds = healthyClusters.map((cluster) => cluster.uuid)
      updateParams({ clusterId: clusterIds })
    }
  }, [clustersLoading, importedClustersLoading, healthyClusters, allClusters])

  const { loading: vmsLoading } = useListAction(listVirtualMachines, {
    params,
    requiredParams: ['clusterId'],
  })

  const { loading: vmisLoading } = useListAction(listVirtualMachineInstances, {
    params,
    requiredParams: ['clusterId'],
  })

  const { loading: storageClassesLoading } = useListAction(listStorageClasses, {
    params,
    requiredParams: ['clusterId'],
  })

  const { loading: persistentVolumesLoading } = useListAction(listPersistentVolumes, {
    params,
    requiredParams: ['clusterId'],
  })

  const { loading: pvcsLoading } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })

  const { loading: dataVolumesLoading } = useListAction(listDataVolumes, {
    params,
    requiredParams: ['clusterId'],
  })

  const {
    vms,
    runningVms,
    pausedVms,
    stoppedVms,
    vmis,
    runningVmis,
    storageClasses,
    pvs,
    pvcs,
    dataVolumes,
  } = useSelectorWithParams(kubevirtDashboardResourcesSelector, {
    ...params,
    useGlobalParams: false,
  })

  const clusterData = useMemo(() => {
    return [
      {
        label: 'Healthy',
        value: healthyClusters?.length,
        loading: clustersLoading || importedClustersLoading,
      },
      {
        label: 'Unhealthy',
        value: allClusters?.length - healthyClusters?.length,
        loading: clustersLoading || importedClustersLoading,
      },
    ]
  }, [allClusters, healthyClusters, clustersLoading, importedClustersLoading])

  const nodeData = useMemo(() => {
    const numHealthyNodes = allNodes.filter((node) => node.status === 'ok')
    return [
      {
        label: 'Healthy',
        value: numHealthyNodes?.length,
        loading: clustersLoading || importedClustersLoading,
      },
      {
        label: 'Unhealthy',
        value: allNodes?.length - numHealthyNodes?.length,
        loading: clustersLoading || importedClustersLoading,
      },
    ]
  }, [allNodes, clustersLoading, importedClustersLoading])

  const vmData = useMemo(() => {
    return [
      { label: 'Total', value: vms?.length, loading: vmsLoading },
      {
        label: 'Running',
        value: runningVms?.length,
        loading: vmsLoading,
      },
      {
        label: 'Paused',
        value: pausedVms?.length,
        loading: vmsLoading,
      },
      {
        label: 'Stopped',
        value: stoppedVms?.length,
        loading: vmsLoading,
      },
    ]
  }, [vms, runningVms, pausedVms, stoppedVms, vmsLoading])

  const vmiData = useMemo(() => {
    return [
      { label: 'Total', value: vmis?.length, loading: vmisLoading },
      {
        label: 'Running',
        value: runningVmis?.length,
        loading: vmisLoading,
      },
    ]
  }, [vmis, runningVmis, vmisLoading])

  const storageData = useMemo(() => {
    return [
      { label: 'Classes', value: storageClasses?.length, loading: storageClassesLoading },
      {
        label: 'PV',
        value: pvs?.length,
        loading: persistentVolumesLoading,
      },
      {
        label: 'PVC',
        value: pvcs?.length,
        loading: pvcsLoading,
      },
      {
        label: 'Data Volume',
        value: dataVolumes?.length,
        loading: dataVolumesLoading,
      },
    ]
  }, [
    storageClasses,
    pvs,
    pvcs,
    dataVolumes,
    storageClassesLoading,
    persistentVolumesLoading,
    pvcsLoading,
    dataVolumesLoading,
  ])

  const kubevirtAddons = useSelector(kubevirtAddonsSelector)

  const kubevirtGraphData = useMemo(() => {
    if (!kubevirtAddons?.length) {
      return noPieData
    }
    return [
      {
        name: 'Installed',
        value: kubevirtAddons?.filter((addon) => addon?.phase === 'Installed')?.length || 0,
        color: 'fadedSuccess',
      },
      {
        name: 'Installing',
        value: kubevirtAddons?.filter((addon) => addon?.phase === 'Installing')?.length || 0,
        color: 'fadedWarning',
      },
      {
        name: 'Unhealthy',
        value:
          kubevirtAddons?.filter((addon) =>
            ['InstallAddonError', 'UninstallAddonError', 'Failed'].includes(addon?.phase),
          )?.length || 0,
        color: 'fadedDanger',
      },
    ]
  }, [kubevirtAddons]) as PieDataEntry[]

  return (
    <div>
      <DocLinks />
      <div className={classes.topCards}>
        <StatCard title="Clusters" data={clusterData} />
        <StatCard title="Hosts" data={nodeData} />
        <StatCard
          title={
            <CardHeader>
              <SimpleLink
                textVariant="subtitle2"
                className={classes.cardLink}
                src={routes.storage.persistentVolumeClaims.path()}
              >
                Storage
              </SimpleLink>
            </CardHeader>
          }
          data={storageData}
        />
      </div>
      <div className={classes.bottomCards}>
        <Card title="KubeVirt Status" withCustomBody>
          <div className={classes.donutContainer}>
            <DonutWidget data={kubevirtGraphData} arcWidth={52} sideLength={208} />
          </div>
        </Card>
        <StatCard
          title="Virtual Machines"
          data={vmData}
          info="Click for more information on when to use Virtual Machines"
          infoLink={vmsLink}
        />
        <StatCard
          title="Virtual Machine Instances"
          data={vmiData}
          info="Click for more information on when to use Virtual Machine Instances"
          infoLink={vmisLink}
        />
      </div>
    </div>
  )
}
