import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import CardHeader from 'core/elements/card/CardHeader'
import Tooltip from 'core/elements/tooltip'
import { topMiddle } from 'core/elements/menu/defaults'
import ExternalLink from 'core/components/ExternalLink'

const useStyles = makeStyles<Theme>((theme) => ({
  properties: {
    display: 'inline-grid',
    gap: 32,
    gridAutoFlow: 'column',
    padding: 24,
  },
  property: {
    display: 'grid',
    gap: 4,
  },
  normalWeight: {
    fontWeight: 'normal',
  },
  spinner: {
    marginTop: 24,
  },
  titleContainer: {
    display: 'flex',
  },
  flexGrow: {
    flexGrow: 1,
  },
  icon: {
    padding: '24px 16px 0px',
  },
}))

interface Data {
  label: string
  value: string | number
  loading?: boolean
}

interface Props {
  title: string | React.ReactNode
  data: Data[]
  info?: React.ReactNode | string
  infoLink?: string
}

const CardHeaderWithInfo = ({ title, info, infoLink }) => {
  const classes = useStyles()

  return (
    <div className={classes.titleContainer}>
      <CardHeader className={classes.flexGrow}>{title}</CardHeader>
      <Tooltip
        message={info}
        align={topMiddle.align}
        offset={topMiddle.offset}
        origin="right center"
      >
        {infoLink ? (
          <ExternalLink url={infoLink}>
            <FontAwesomeIcon className={classes.icon}>question-circle</FontAwesomeIcon>
          </ExternalLink>
        ) : (
          <FontAwesomeIcon className={classes.icon}>question-circle</FontAwesomeIcon>
        )}
      </Tooltip>
    </div>
  )
}

export default function StatCard({ title, info = undefined, infoLink = undefined, data }: Props) {
  const classes = useStyles()

  const Title = info ? <CardHeaderWithInfo title={title} info={info} infoLink={infoLink} /> : title
  return (
    <Card title={Title} withCustomBody>
      <div className={classes.properties}>
        {data.map((prop) => (
          <div className={classes.property} key={prop.label}>
            <Text variant="caption1">{prop.label}</Text>
            {prop.loading ? (
              <FontAwesomeIcon className={classes.spinner} size="xl" solid spin>
                circle-notch
              </FontAwesomeIcon>
            ) : (
              <Text variant="h2" className={classes.normalWeight}>
                {prop.value}
              </Text>
            )}
          </div>
        ))}
      </div>
    </Card>
  )
}
