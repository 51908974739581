import React from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overviewCellsContainer: {
    marginTop: 24,
    marginBottom: 68,
    borderTop: `16px solid ${theme.components.table.hoverBackground}`,
    borderBottom: `16px solid ${theme.components.table.hoverBackground}`,
    background: theme.components.table.border,
    display: 'grid',
    gap: '1px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  overviewCell: {
    background: theme.components.table.hoverBackground,
    paddingLeft: 24,
  },
}))

interface Info {
  label: string
  value?: string | number
  customValue?: React.ReactNode
}

interface Props {
  data: Info[]
}

export default function InfoBlocks({ data }: Props) {
  const classes = useStyles({})

  return (
    <div className={classes.overviewCellsContainer}>
      {data.map((info) => (
        <div className={classes.overviewCell} key={info.label}>
          <Text variant="body2">{info.label}</Text>
          {info?.customValue ? info.customValue : <Text variant="caption1">{info.value}</Text>}
        </div>
      ))}
    </div>
  )
}
