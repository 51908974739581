import { createSharedSelector } from 'core/utils/selectorHelpers'
import {
  virtualMachinesSelector,
  virtualMachineInstancesSelector,
} from '../virtual-machines/selectors'
import { storageClassSelector } from 'k8s/components/storage/storage-classes/selectors'
import { persistentVolumeSelector } from 'k8s/components/storage/persistent-volume/selectors'
import { persistentVolumeClaimsSelector } from 'k8s/components/storage/persistent-volume-claims/selectors'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'

export const kubevirtDashboardResourcesSelector = createSharedSelector(
  virtualMachinesSelector,
  virtualMachineInstancesSelector,
  storageClassSelector,
  persistentVolumeSelector,
  persistentVolumeClaimsSelector,
  dataVolumesSelector,
  (vms, vmis, storageClasses, pvs, pvcs, dataVolumes) => {
    return {
      vms,
      runningVms: vms?.filter((vm) => vm?.status?.printableStatus === 'Running'),
      pausedVms: vms?.filter((vm) => vm?.status?.printableStatus === 'Paused'),
      stoppedVms: vms?.filter((vm) => vm?.status?.printableStatus === 'Stopped'),
      vmis,
      runningVmis: vmis?.filter((vmi) => vmi?.status?.phase === 'Running'),
      storageClasses,
      pvs,
      pvcs,
      dataVolumes,
    }
  },
)
