import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import { renderAgeFromTimestamp } from 'k8s/components/common/entity/helpers'
import Grid from 'core/elements/grid'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { makePersistentVolumeClaimSelector } from 'k8s/components/storage/persistent-volume-claims/selectors'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { getVmiStatus } from '../VmOverviewPage'
import Badge from 'core/elements/badge/Badge'
import { diskColumns, getDisks } from '../vm-details/helpers'
import InfoBlocks from '../vm-details/InfoBlocks'
import ResourceInfo from '../vm-details/ResourceInfo'
import Text from 'core/elements/Text'
import { IVirtualMachineDetailsPageTabs } from '../model'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import EntityEventsPage from 'k8s/components/common/entity/entity-events-page'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
  customCardHeader: {
    display: 'flex',
    padding: '16px 32px 0px',
    gap: 8,
    alignItems: 'center',
  },
  borderTop: {
    borderTop: `1px solid ${theme.components.table.border}`,
  },
}))

const pvcSelector = makePersistentVolumeClaimSelector()

export const renderPhase = (status, vmi) => {
  if (!status) return ''
  const { variant } = getVmiStatus(status, vmi?.paused)
  return <Badge variant={variant} text={vmi?.paused ? 'Paused' : status} />
}

const renderVmiReady = (conditions) => {
  const readyCondition = conditions.find((cond) => cond.type === 'Ready')
  return readyCondition?.status
}

export const networkColumns = [
  {
    key: 'networkName',
    label: 'Network',
  },
  { key: 'ipAddress', label: 'IP Address', formatFn: (value: string) => (value ? value : 'N/A') },
  { key: 'mac', label: 'MAC Address', formatFn: (value: string) => (value ? value : 'N/A') },
]

const metadataFields = [
  {
    id: 'status.conditions',
    title: 'Ready',
    render: renderVmiReady,
  },
  {
    id: 'status.phase',
    title: 'VMI Phase',
    render: renderPhase,
  },
  {
    id: 'created',
    title: 'Created',
    required: true,
    render: renderAgeFromTimestamp,
  },
]

export default function Overview({ vmi, loading }) {
  const classes = useStyles()
  const params = useMemo(
    () => ({
      clusterId: vmi?.clusterId,
      namespace: vmi?.namespace,
    }),
    [vmi],
  )

  const { loading: loadingDataVolumes } = useListAction(listDataVolumes, {
    params,
    requiredParams: ['clusterId'],
  })
  const dataVolumes = useSelectorWithParams(dataVolumesSelector, params)

  const { loading: loadingPvcs } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })
  const pvcs = useSelectorWithParams(pvcSelector, params)

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, vmi)
  }, [vmi])

  const disks = useMemo(() => getDisks({ entity: vmi, dataVolumes, pvcs }), [
    vmi,
    dataVolumes,
    pvcs,
  ])

  const entities = useMemo(() => {
    const pods =
      vmi?.podIds?.map((id) => ({
        id,
      })) || []
    return [vmi, ...pods].filter((entity) => !!entity)
  }, [vmi])

  const overviewData = useMemo(
    () => [
      {
        label: 'Host',
        value: vmi?.status?.nodeName,
      },
      {
        label: 'Cluster',
        value: vmi?.clusterName,
      },
      {
        label: 'Namespace',
        value: vmi?.namespace,
      },
      {
        label: 'VM',
        customValue: (
          <SimpleLink
            textVariant="caption1"
            src={
              vmi?.vmOwner?.name &&
              routes.virtualMachines.details.path({
                clusterId: vmi?.clusterId,
                id: vmi?.vmOwner?.uid,
                tab: IVirtualMachineDetailsPageTabs.Overview,
              })
            }
          >
            {vmi?.vmOwner?.name}
          </SimpleLink>
        ),
      },
    ],
    [vmi],
  )

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <Card
            title={
              <div className={classes.customCardHeader}>
                <Text variant="subtitle2">{vmi.name}</Text>
                {renderPhase(vmi?.status?.phase, vmi)}
              </div>
            }
          >
            <Text variant="body2">Virtual Machine Instance</Text>
            <InfoBlocks data={overviewData} />
          </Card>
          <InfoCard
            items={metadata}
            title="Properties"
            footer={<LabelsAndAnnotationsSection entity={vmi} resourceType="vmi" />}
          />
          <Card title="Networks" withCustomBody>
            <Grid
              uniqueIdentifier="networkName"
              data={vmi?.networks || []}
              columns={networkColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <Card title="Resources" withCustomBody>
            <ResourceInfo type="CPU" fields={vmi?.cpu} />
            <ResourceInfo className={classes.borderTop} type="Memory" fields={vmi?.memory} />
          </Card>
          <EntityEventsPage entity={entities} loading={loading} noMargin />
          <Card title="Disks" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={disks}
              columns={diskColumns}
              loading={loadingDataVolumes || loadingPvcs}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}
