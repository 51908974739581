import React from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import RadioFields from 'core/components/validatedForm/radio-fields'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { allInstanceTypesSelector } from '../instance-types/selectors'
import { listInstanceTypes, listClusterInstanceTypes } from '../instance-types/actions'
import UnitPicklist from './UnitPicklist'
import { IInstanceTypeSelector } from '../instance-types/instance-types-model'
import PicklistField from 'core/components/validatedForm/DropdownField'
import Alert from 'core/components/Alert'

const useStyles = makeStyles<Theme>((theme) => ({
  customSpecs: {
    display: 'grid',
    gridAutoFlow: 'column',
    width: 350,
    gridGap: theme.spacing(3),
    gridTemplateColumns: '1fr 1fr',
    marginTop: theme.spacing(1),
  },
  specInput: {
    width: '100% !important',
  },
  parent: {
    position: 'relative',
  },
  unitLabel: {
    position: 'absolute',
    top: 36,
    right: 36,
  },
  sameLine: {
    display: 'flex',
    alignItems: 'center',
  },
  radioLabel: {
    fontSize: 14,
  },
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
}))

const specifyCustom = [
  {
    key: 'custom',
    value: 'custom',
    label: 'Specify custom values for CPU and Memory',
  },
]

const selectPreset = [
  {
    key: 'useInstanceType',
    value: 'useInstanceType',
    label: 'Instancetypes',
  },
]

const columns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'spec.cpu.guest',
    label: 'vCPUs',
  },
  {
    id: 'spec.memory.guest',
    label: 'Memory',
  },
  {
    id: 'spec.cpu.dedicatedCPUPlacement',
    label: 'Dedicated CPU Placement',
    render: (val: boolean) => (val ? 'Enabled' : 'Disabled'),
  },
  {
    id: 'spec.memory.hugepages.pageSize',
    label: 'Huge Pages',
    render: (val: string) => (val ? val : 'N/A'),
  },
  {
    id: 'namespace',
    label: 'Scope',
    render: (val: string) => (val ? `Namespace: ${val}` : 'Cluster Scoped'),
  },
]

export const ResourceStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})

  const { loading: loadingInstanceTypes, reload: reloadInstanceTypes } = useListAction(
    listInstanceTypes,
    {
      params: {
        clusterId: wizardContext.clusterId,
      },
      requiredParams: ['clusterId'],
    },
  )
  const {
    loading: loadingClusterInstanceTypes,
    reload: reloadClusterInstanceTypes,
  } = useListAction(listClusterInstanceTypes, {
    params: {
      clusterId: wizardContext.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const instanceTypes = useSelectorWithParams(allInstanceTypesSelector, {
    clusterId: wizardContext.clusterId,
    // currently will never filter by namespace unless using global params
    // namespace: wizardContext.namespace,
    useGlobalParams: false,
  })

  // Explicit typing to get around IClusterInstanceType not having namespace
  const namespaceInstanceTypes = instanceTypes.filter((instanceType: IInstanceTypeSelector) => {
    if (instanceType?.namespace) {
      return instanceType.namespace === wizardContext.namespace
    }
    return true
  })

  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={onNext}
      elevated={false}
    >
      <FormFieldSection title="Select method of configuring resources.">
        {wizardContext?.vmType === 'VirtualMachine' && (
          <Text variant="body2">
            Specify custom values for VCPU and RAM or select a pre-defined instancetype.
          </Text>
        )}
        <RadioFields
          id="resourceOption"
          options={specifyCustom}
          value={wizardContext.resourceOption}
          onChange={(value) =>
            setWizardContext({
              resourceOption: value,
            })
          }
        />
        {wizardContext.resourceOption === 'custom' && (
          <>
            <TextField
              id="vcpus"
              label="Cores"
              onChange={(value) => setWizardContext({ vcpus: value })}
              value={wizardContext.vcpus}
              type="number"
              className={classes.specInput}
              min={1}
              required
            />
            <div className={classes.withUnits}>
              <TextField
                id="ram"
                label="Memory"
                onChange={(value) => setWizardContext({ ram: value })}
                value={wizardContext.ram}
                type="number"
                min="1"
                required
              />
              <PicklistField
                DropdownComponent={UnitPicklist}
                id="ramUnit"
                onChange={(value) => setWizardContext({ ramUnit: value })}
                className={classes.unitsDropdown}
                required
              />
            </div>
          </>
        )}
        {wizardContext?.vmType === 'VirtualMachine' && (
          <>
            <RadioFields
              id="resourceOption"
              options={selectPreset}
              value={wizardContext.resourceOption}
              onChange={(value) =>
                setWizardContext({
                  resourceOption: value,
                })
              }
            />
            {wizardContext.resourceOption === 'useInstanceType' && (
              <>
                <Alert
                  variant="primary"
                  message={
                    <Text variant="body2">
                      <b>Note:</b> Instancetypes created outside of the web application with
                      dedicated CPU placement or hugepages enabled will not work with the current
                      version of Kubevirt.
                    </Text>
                  }
                />
                <ListTableField
                  id="instanceType"
                  data={namespaceInstanceTypes}
                  loading={loadingInstanceTypes || loadingClusterInstanceTypes}
                  columns={columns}
                  onChange={(value) => setWizardContext({ instanceType: value })}
                  value={wizardContext.instanceType}
                  onReload={() => {
                    reloadInstanceTypes(true, true)
                    reloadClusterInstanceTypes(true, true)
                  }}
                  required
                />
                {/* { wizardContext?.instanceType?.[0]?.spec?.cpu?.dedicatedCPUPlacement ||
                wizardContext?.instanceType?.[0]?.spec?.memory?.hugepages?.pageSize && (
                  <div className={classes.withUnits}>
                    <TextField
                      id="requestMemory"
                      label="Memory (Requests)"
                      onChange={(value) => setWizardContext({ requestMemory: value })}
                      value={wizardContext.requestMemory}
                      type="number"
                      min="1"
                      info="If hugepages is set on the instancetype, memory requests must be larger than hugepages size."
                      required
                    />
                    <PicklistField
                      DropdownComponent={UnitPicklist}
                      id="requestMemoryUnit"
                      onChange={(value) => setWizardContext({ requestMemoryUnit: value })}
                      className={classes.unitsDropdown}
                      required
                    />
                  </div>
                )} */}
              </>
            )}
          </>
        )}
      </FormFieldSection>
    </ValidatedForm>
  )
}

export default ResourceStep
