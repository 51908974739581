import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { keys } from 'ramda'
import Text from 'core/elements/Text'
import clsx from 'clsx'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  resourceInfo: {
    padding: '16px 24px',
  },
  resourceProperties: {
    marginTop: 8,
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: 24,
  },
  resourceProperty: {
    display: 'grid',
    gap: 4,
  },
  resourceKey: {
    textTransform: 'capitalize',
    color: theme.components.typography.passive,
  },
}))

export default function ResourceInfo({ type, fields, className = '' }) {
  const classes = useStyles()
  const fieldKeys = keys(fields)

  return (
    <div className={clsx(classes.resourceInfo, className)}>
      <Text variant="subtitle2">{type}</Text>
      <div className={classes.resourceProperties}>
        {fieldKeys.map((key) => (
          <div key={key.toString()} className={classes.resourceProperty}>
            <Text variant="caption1" className={classes.resourceKey}>
              {key}
            </Text>
            <Text variant="subtitle2">{fields[key].toString() || '-'}</Text>
          </div>
        ))}
      </div>
    </div>
  )
}
