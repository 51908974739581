import React, { useCallback, useEffect, useMemo } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import RadioFields from 'core/components/validatedForm/radio-fields'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import AccessModePicklist from './AccessModePicklist'
import StorageClassesPicklist from 'k8s/components/storage/StorageClassesPicklist'
import uuid from 'uuid'
import clsx from 'clsx'
import { noneKey } from 'app/constants'
import CodeBlock from 'core/components/CodeBlock'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import {
  persistentVolumeClaimsSelector,
  availablePvcsSelector,
} from 'k8s/components/storage/persistent-volume-claims/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { requiredValidator, minValueValidator } from 'core/utils/fieldValidators'
import { listPods } from 'k8s/components/pods/new-actions'

const useStyles = makeStyles<Theme>((theme) => ({
  tabbed: {
    marginLeft: theme.spacing(4.5),
  },
  storageItems: {
    display: 'grid',
    gridGap: theme.spacing(3),
    margin: theme.spacing(3, 0),
  },
  storageItem: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  storageItemIcon: {
    marginTop: theme.spacing(3),
    cursor: 'pointer',
    color: theme.palette.blue[500],
  },
  storageFields: {
    marginLeft: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    flexGrow: 1,
    padding: theme.spacing(4),
    display: 'grid',
    gap: 8,
    overflowY: 'hidden',
  },
  addDisk: {
    display: 'inline-flex',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer',
    margin: theme.spacing(2, 0, 4),
  },
  addIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.blue[500],
  },
  storageSizeInput: {
    width: '100% !important',
    top: theme.spacing(0.5),
  },
  radioLabel: {
    fontSize: 14,
  },
  disabled: {
    filter: 'grayscale(1)',
  },
}))

const storageSizeValidators = [minValueValidator(1), requiredValidator]

const fromHttpUrl = [
  {
    key: 'httpUrl',
    value: 'httpUrl',
    label: 'Import an Image from HTTP URL',
  },
]

const fromRegistryUrl = [
  {
    key: 'registryUrl',
    value: 'registryUrl',
    label: 'Import an Image from Registry URL',
  },
]

const fromDisk = [
  {
    key: 'disk',
    value: 'disk',
    label: 'Upload an Image from Disk',
  },
]

const fromExisting = [
  {
    key: 'existing',
    value: 'existing',
    label: 'Attach Existing PVC (Only Available PVCs are displayed)',
  },
]

const fromClone = [
  {
    key: 'clone',
    value: 'clone',
    label: 'Clone Existing PVC',
  },
]

const fromVolume = [
  {
    key: 'volume',
    value: 'volume',
    label: 'Attach Existing Disk',
  },
]

const columns = [
  { id: 'name', label: 'Name' },
  {
    id: 'spec.pvc.resources.requests.storage',
    label: 'Size',
    render: (value, item) => {
      return value ? value : item?.spec?.storage?.resources?.requests?.storage
    },
  },
]

const pvcColumns = [
  { id: 'name', label: 'Name' },
  { id: 'spec.resources.requests.storage', label: 'Size' },
]

const isValidPvcCombination = (wizardContext, disk) => {
  return (
    (disk.sourceType === 'existing' && wizardContext.vmType === 'VirtualMachineInstance') ||
    (disk.sourceType === 'clone' && wizardContext.vmType === 'VirtualMachine')
  )
}

export const StorageStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})

  const { loading, reload: reloadDataVolumes } = useListAction(listDataVolumes, {
    params: {
      clusterId: wizardContext.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const dataVolumes = useSelectorWithParams(dataVolumesSelector, {
    clusterId: wizardContext.clusterId,
    namespace: wizardContext.namespace,
    useGlobalParams: false,
  })

  const { loading: pvcsLoading, reload: reloadPvcs } = useListAction(listPersistentVolumeClaims, {
    params: {
      clusterId: wizardContext.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const pvcs = useSelectorWithParams(persistentVolumeClaimsSelector, {
    clusterId: wizardContext.clusterId,
    namespace: wizardContext.namespace,
    useGlobalParams: false,
  })

  const { loading: podsLoading, reload: reloadPods } = useListAction(listPods, {
    params: {
      clusterId: wizardContext.clusterId,
      namespace: wizardContext.namespace,
    },
    requiredParams: ['clusterId', 'namespace'],
  })

  const availablePvcs = useSelectorWithParams(availablePvcsSelector, {
    clusterId: wizardContext.clusterId,
    namespace: wizardContext.namespace,
    useGlobalParams: false,
  })

  const addStorageDisk = useCallback(() => {
    setWizardContext({
      storageDisks: [
        ...wizardContext.storageDisks,
        {
          rowId: uuid.v4(),
          accessMode: 'ReadWriteMany',
          sourceType: 'httpUrl',
          storageClass: noneKey,
        },
      ],
    })
  }, [wizardContext.storageDisks, setWizardContext])

  const updateStorageDisk = useCallback(
    (rowId, value) => {
      const idx = wizardContext.storageDisks.findIndex((disk) => disk.rowId === rowId)
      if (idx < 0) {
        return
      }
      const storageDisks = [...wizardContext.storageDisks]
      storageDisks.splice(idx, 1, {
        ...wizardContext.storageDisks[idx],
        ...value,
      })
      setWizardContext({
        storageDisks,
      })
    },
    [wizardContext.storageDisks, setWizardContext],
  )

  const removeStorageDisk = useCallback(
    (rowId) => {
      setWizardContext({
        storageDisks: wizardContext.storageDisks.filter((item) => item.rowId === rowId),
      })
    },
    [wizardContext.storageDisks, setWizardContext],
  )

  useEffect(() => {
    if (!wizardContext.storageDisks.length) {
      addStorageDisk()
    }
  }, [])

  return (
    <ValidatedForm
      // Because setting of storageDisks is done within the component, and
      // having onSubmit will reset the value
      // onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={onNext}
      elevated={false}
    >
      <FormFieldSection title="Storage Selection">
        <Text variant="body2">Specify storage type and details.</Text>
        <div className={classes.storageItems}>
          {wizardContext.storageDisks.map((disk, idx) => (
            <div className={classes.storageItem} key={disk.rowId}>
              <FontAwesomeIcon
                className={clsx(classes.storageItemIcon, idx === 0 && classes.disabled)}
                onClick={() => {
                  if (idx === 0) {
                    return
                  }
                  removeStorageDisk(disk.rowId)
                }}
                solid
              >
                minus-circle
              </FontAwesomeIcon>
              <div className={classes.storageFields}>
                <AccessModePicklist
                  value={disk.accessMode}
                  onChange={(value) => updateStorageDisk(disk.rowId, { accessMode: value })}
                />
                {/* TODO: Make 'GiB' show again after Input is updated */}
                {['httpUrl', 'registryUrl', 'disk'].includes(disk.sourceType) && (
                  <TextField
                    className={classes.storageSizeInput}
                    id={`storageSize-${disk.rowId}`}
                    label="Size (GiB)"
                    onChange={(value) => updateStorageDisk(disk.rowId, { storageSize: value })}
                    value={disk.storageSize}
                    min={1}
                    type="number"
                    validations={storageSizeValidators}
                    required
                  />
                )}
                <StorageClassesPicklist
                  value={disk.storageClass}
                  onChange={(value) => updateStorageDisk(disk.rowId, { storageClass: value })}
                  clusterId={wizardContext.clusterId}
                />
                <div>
                  {wizardContext.vmType === 'VirtualMachine' && (
                    <>
                      <RadioFields
                        id={`sourceType-${disk.rowId}`}
                        options={fromHttpUrl}
                        value={disk.sourceType}
                        onChange={(value) =>
                          updateStorageDisk(disk.rowId, {
                            sourceType: value,
                          })
                        }
                      />
                      {disk.sourceType === 'httpUrl' && (
                        <TextField
                          id={`httpUrl-${disk.rowId}`}
                          label="HTTP URL"
                          onChange={(value) => updateStorageDisk(disk.rowId, { httpUrl: value })}
                          value={disk.httpUrl}
                          required
                        />
                      )}
                      <RadioFields
                        id={`sourceType-${disk.rowId}`}
                        options={fromRegistryUrl}
                        value={disk.sourceType}
                        onChange={(value) =>
                          updateStorageDisk(disk.rowId, {
                            sourceType: value,
                          })
                        }
                      />
                      {disk.sourceType === 'registryUrl' && (
                        <TextField
                          id={`registryUrl-${disk.rowId}`}
                          label="Registry URL"
                          onChange={(value) =>
                            updateStorageDisk(disk.rowId, { registryUrl: value })
                          }
                          value={disk.registryUrl}
                          required
                        />
                      )}
                      <RadioFields
                        id={`sourceType-${disk.rowId}`}
                        options={fromDisk}
                        value={disk.sourceType}
                        onChange={(value) =>
                          updateStorageDisk(disk.rowId, {
                            sourceType: value,
                          })
                        }
                      />
                      {disk.sourceType === 'disk' && (
                        <>
                          <Text variant="body2">
                            You can run the virtctl image-upload command as below to upload a VM
                            image to a PVC or DataVolume:
                          </Text>
                          <CodeBlock>
                            {`# virtctl image-upload --pvc-name=<upload-pvc> --pvc-size=<1Gi> --image-path=</your/centos/image.qcow2>
# virtctl image-upload dv dv-name --size=10Gi --image-path=/images/fedora30.qcow2`}
                          </CodeBlock>
                        </>
                      )}
                    </>
                  )}
                  {!wizardContext.createMultiple && (
                    <>
                      {wizardContext.vmType === 'VirtualMachine' && (
                        <RadioFields
                          id={`sourceType-${disk.rowId}`}
                          options={fromClone}
                          value={disk.sourceType}
                          onChange={(value) =>
                            updateStorageDisk(disk.rowId, {
                              sourceType: value,
                            })
                          }
                        />
                      )}
                      {wizardContext.vmType === 'VirtualMachineInstance' && (
                        <RadioFields
                          id={`sourceType-${disk.rowId}`}
                          options={fromExisting}
                          value={disk.sourceType}
                          onChange={(value) =>
                            updateStorageDisk(disk.rowId, {
                              sourceType: value,
                            })
                          }
                        />
                      )}

                      {isValidPvcCombination(wizardContext, disk) && (
                        <div>
                          <ListTableField
                            id={`selectedPvcs-${disk.rowId}`}
                            data={disk.sourceType === 'existing' ? availablePvcs : pvcs}
                            loading={pvcsLoading || podsLoading}
                            columns={pvcColumns}
                            onChange={(value) =>
                              updateStorageDisk(disk.rowId, { selectedPvcs: value })
                            }
                            value={disk.selectedPvcs}
                            onReload={() => {
                              reloadPvcs(true, true)
                              reloadPods(true, true)
                            }}
                            required
                          />
                        </div>
                      )}
                      <RadioFields
                        id={`sourceType-${disk.rowId}`}
                        options={fromVolume}
                        value={disk.sourceType}
                        onChange={(value) =>
                          updateStorageDisk(disk.rowId, {
                            sourceType: value,
                          })
                        }
                      />
                      {disk.sourceType === 'volume' && (
                        <ListTableField
                          id={`selectedDataVolumes-${disk.rowId}`}
                          data={dataVolumes}
                          loading={loading}
                          columns={columns}
                          onChange={(value) =>
                            updateStorageDisk(disk.rowId, { selectedDataVolumes: value })
                          }
                          value={disk.selectedDataVolumes}
                          onReload={() => {
                            reloadDataVolumes(true, true)
                          }}
                          required
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.addDisk} onClick={addStorageDisk}>
          <FontAwesomeIcon className={classes.addIcon} solid>
            plus-circle
          </FontAwesomeIcon>
          <Text variant="caption1">Add Another Storage Disk</Text>
        </div>
      </FormFieldSection>
    </ValidatedForm>
  )
}

export default StorageStep
