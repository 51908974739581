import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import { routes } from 'core/utils/routes'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import {
  listVirtualMachines,
  listVirtualMachineInstances,
  updateVirtualMachine,
} from '../new-actions'
import { virtualMachinesSelector } from '../selectors'
import { IVirtualMachineDetailsPageTabs, IVirtualMachineSelector } from '../model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import OverviewActions from 'core/elements/overview/OverviewActions'
import VirtualMachinePowerOperationDialog from '../VirtualMachinePowerOperationDialog'
import VmiPowerOperationDialog from '../VmiPowerOperationDialog'
import Overview from './Overview'
import { useSelector } from 'react-redux'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { listServices } from 'k8s/components/services/new-actions'
import { serviceSelectors } from 'k8s/components/services/selectors'
import MigrateVmiDialog from './MigrateVmiDialog'
import DeleteVmDialog from '../DeleteVmDialog'
import MigrationStatus from './MigrationStatus'
import EditVmDialog from './EditVmDialog'
import ChangeVmInstanceTypeDialog from './ChangeVmInstanceTypeDialog'

const { qbert } = ApiClient.getInstance()

const defaultVm = {} as IVirtualMachineSelector

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
}))

const VirtualMachineDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { reload: reloadVmis } = useListAction(listVirtualMachineInstances, {
    params: { clusterId },
  })
  const { loading, reload } = useListAction(listVirtualMachines, {
    params: { clusterId },
  })
  const vms = useSelectorWithParams(virtualMachinesSelector, { clusterId, useGlobalParams: false })
  const vm = useMemo(() => vms.find((vm) => vm.id === id) || defaultVm, [id, vms])
  const { update, updating } = useUpdateAction(updateVirtualMachine)

  const { loading: loadingClusters } = useListAction(listClusters)
  const clusters = useSelector(clustersSelector)

  const { loading: loadingServices } = useListAction(listServices, {
    params: {
      clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const services = useSelectorWithParams(serviceSelectors, {
    clusterId,
    namespace: vm?.namespace,
    useGlobalParams: false,
  })

  const cluster = useMemo(() => clusters.find((c) => c?.uuid === vm?.clusterId), [clusters, vm])
  const service = useMemo(() => services.find((s) => s?.name === 'virtvnc'), [services])

  const reloadVms = useCallback(() => {
    reload(true, true)
    reloadVmis(true, true)
  }, [reload])

  const actions = useMemo(
    () => ({
      leftActions: [
        {
          label: 'Start',
          icon: 'play',
          DialogComponent: VirtualMachinePowerOperationDialog,
          refreshFn: reloadVms,
          hideFn: (vm) =>
            vm?.status?.printableStatus === 'Running' || vm?.status?.printableStatus === 'Paused',
          customDialogProps: {
            params: {
              operation: 'start',
            },
          },
        },
        {
          label: 'Stop',
          icon: 'stop',
          DialogComponent: VirtualMachinePowerOperationDialog,
          refreshFn: reloadVms,
          hideFn: (vm) => vm?.status?.printableStatus === 'Stopped',
          customDialogProps: {
            params: {
              operation: 'stop',
            },
          },
        },
        {
          label: 'Restart',
          icon: 'power-off',
          DialogComponent: VirtualMachinePowerOperationDialog,
          refreshFn: reloadVms,
          hideFn: (vm) => vm?.status?.printableStatus !== 'Running',
          customDialogProps: {
            params: {
              operation: 'restart',
            },
          },
        },
        {
          label: 'Pause',
          icon: 'pause',
          DialogComponent: VmiPowerOperationDialog,
          refreshFn: reloadVms,
          hideFn: (vm) => {
            if (!vm?.vmi) {
              return true
            }
            return vm?.status?.printableStatus !== 'Running'
          },
          tooltipFn: (vm) => {
            return vm?.vmi ? '' : 'No virtual machine instance associated with this VM'
          },
          customDialogProps: {
            params: {
              operation: 'pause',
            },
          },
        },
        {
          label: 'Unpause',
          icon: 'play',
          DialogComponent: VmiPowerOperationDialog,
          refreshFn: reloadVms,
          hideFn: (vm) => {
            if (!vm?.vmi) {
              return true
            }
            return vm?.status?.printableStatus !== 'Paused'
          },
          tooltipFn: (vm) => {
            return vm?.vmi ? '' : 'No virtual machine instance associated with this VM'
          },
          customDialogProps: {
            params: {
              operation: 'unpause',
            },
          },
        },
      ],
      rightActions: [
        {
          label: 'Console',
          icon: 'terminal',
          disabledFn: (vm) => {
            return !vm?.vmi || loadingClusters || loadingServices || !service
          },
          tooltipFn: (vm) => {
            return vm?.vmi ? '' : 'A virtual machine instance is required to access the console'
          },
          // Todo: What if theres more than 1 port?
          // gray out if there's no VMI
          externalLink: `http://${cluster?.masterIp}:${service?.ports?.[0]?.nodePort}/vnc_lite.html?path=k8s/apis/subresources.kubevirt.io/v1/namespaces/${vm?.namespace}/virtualmachineinstances/${vm?.vmi?.name}/vnc`,
        },
        {
          label: 'Migrate VM',
          icon: 'server',
          disabledFn: (vm) => {
            const condition = vm?.vmi?.status?.conditions?.find(
              (cond) => cond.type === 'LiveMigratable',
            )
            return condition?.status === 'False'
          },
          tooltipFn: (vm) => {
            const condition = vm?.vmi?.status?.conditions?.find(
              (cond) => cond.type === 'LiveMigratable',
            )
            return condition?.status === 'False' ? condition?.message : ''
          },
          DialogComponent: MigrateVmiDialog,
        },
        {
          label: 'Refresh',
          icon: 'sync',
          onClick: reloadVms,
        },
        {
          label: 'Edit',
          icon: 'pen-to-square',
          DialogComponent: vm?.spec?.instancetype?.name ? ChangeVmInstanceTypeDialog : EditVmDialog,
        },
        {
          label: 'Delete VM',
          icon: 'trash',
          DialogComponent: DeleteVmDialog,
        },
      ],
    }),
    [reloadVms, vm, cluster, service, loadingClusters, loadingServices],
  )

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: vm?.clusterName || clusterId,
        id: vm?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [vm?.clusterName, vm?.name, id, clusterId])

  const getVmYaml = useCallback(async () => {
    if (isEmpty(vm)) return undefined
    return qbert.getVirtualMachineByName(vm.clusterId, vm.namespace, vm.name)
  }, [vm])

  const handleVmUpdate = useCallback(
    async (yaml) => {
      const body = jsYaml.load(yaml)
      const namespace = pathStrOr('', 'metadata.namespace', body)
      const name = pathStrOr('', 'metadata.name', body)
      await update({
        clusterId,
        namespace,
        name,
        body,
      })
    },
    [vm],
  )

  return (
    <>
      <DocumentMeta title="Virtual Machine Details" breadcrumbs />
      <div className={classes.grid}>
        <div>
          <OverviewActions<IVirtualMachineSelector> actions={actions} entity={vm} />
        </div>
        <MigrationStatus vmi={vm?.vmi} />
        <div>
          <Tabs route={routes.virtualMachines.details}>
            <Tab label="Overview" value={IVirtualMachineDetailsPageTabs.Overview}>
              <Overview vm={vm} loading={loading} />
            </Tab>
            <Tab label="YAML" value={IVirtualMachineDetailsPageTabs.Yaml}>
              <EntityYamlPage
                entityType="VirtualMachine"
                entityName={vm?.name}
                getYamlFn={getVmYaml}
                handleUpdate={handleVmUpdate}
                loading={loading || updating}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default VirtualMachineDetailsPage
