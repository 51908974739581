import React from 'react'
import Text from 'core/elements/Text'
import { VmiNetwork } from '../vmi-model'
import { renderLabelsAsBadges } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { IPersistentVolumeClaimsSelector } from 'k8s/components/storage/persistent-volume-claims/model'
import { keys } from 'ramda'
import Tooltip from 'core/elements/tooltip'
import { middleLeft } from 'core/elements/menu/defaults'

const DataVolumeStatusCell = ({ value: status }) => {
  return (
    <>
      <Text variant="body2">{status?.phase}</Text>
      {status?.progress && <Text variant="body2">({status?.progress})</Text>}
    </>
  )
}

const DataVolumeSourceCell = ({ value: source }) => {
  if (!source) {
    return null
  }
  const key = keys(source)?.[0]
  const url = source[key]?.url
  return (
    <>
      <Text variant="body2">{key}</Text>
      {url && (
        <Tooltip message={url} align={middleLeft.align}>
          <Text variant="body2">({url})</Text>
        </Tooltip>
      )}
    </>
  )
}

// Todo: If we make a PVC details page, we can link directly to the PVC rather than
// providing a custom grid on the details page, would be preferable
export const diskColumns = [
  {
    key: 'name',
    label: 'Name',
  },
  { key: 'type', label: 'Type' },
  { key: 'spec.pvc.resources.requests.storage', label: 'Capacity' },
  {
    key: 'spec.pvc.accessModes',
    label: 'Access Modes',
    render: renderLabelsAsBadges({ variant: 'default' }),
  },
  { key: 'status', label: 'Status', CellComponent: DataVolumeStatusCell },
  { key: 'spec.source', label: 'Source', CellComponent: DataVolumeSourceCell },
  { key: 'storageClassName', label: 'Storage Class' },
]

const getVolumes = (entity) => {
  return (
    (entity?.kind === 'VirtualMachine'
      ? entity?.spec?.template?.spec?.volumes
      : entity?.spec?.volumes) || []
  )
}

export const getDisks = ({ entity, dataVolumes, pvcs }) => {
  if (!entity) {
    return []
  }
  const volumes = getVolumes(entity)
  return volumes.map((volume) => {
    if (volume?.hasOwnProperty('dataVolume')) {
      const dataVolume = dataVolumes.find((dv) => dv?.name === volume?.dataVolume?.name) || {}
      const pvc =
        pvcs.find((pvc) => pvc?.name === volume?.dataVolume?.name) ||
        ({} as IPersistentVolumeClaimsSelector)
      return {
        name: volume?.dataVolume?.name,
        type: 'dataVolume',
        ...dataVolume,
        storageClassName: pvc?.storageClassName,
      }
    } else if (volume?.hasOwnProperty('cloudInitNoCloud')) {
      return {
        name: volume?.name,
        type: 'cloud-init',
      }
    } else {
      // https://kubevirt.io/user-guide/virtual_machines/disks_and_volumes/#volumes
      // can take the key name, the obj always looks like { <type>: ..., name: ... }
      const volumeKeys = keys(volume)
      const volumeType = volumeKeys.filter((key) => key !== 'name')
      return {
        name: volume?.name,
        type: volumeType,
      }
    }
  })
}

export const splitKubernetesUnitString = (str) => {
  if (!str) {
    return { number: undefined, unit: 'Gi' }
  }
  if (str.includes('Gi')) {
    return { number: str.replace('Gi', ''), unit: 'Gi' }
  } else if (str.includes('Mi')) {
    return { number: str.replace('Mi', ''), unit: 'Mi' }
  } else if (str.includes('G')) {
    return { number: str.replace('G', ''), unit: 'Gi' }
  } else if (str.includes('M')) {
    return { number: str.replace('M', ''), unit: 'Mi' }
  }
  return { number: str, unit: 'Gi' }
}
